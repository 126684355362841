import { Grid, Typography } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  ArcElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";

const PieOption = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Uczestnicy finałów w podziale na klasy i rodzaj szkoły",
    },
  },
};

const BarOption = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: false,
    title: {
      display: true,
      text: "Uczestnicy finałów w podziale na płeć",
    },
  },
};

const BarOption2 = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: false,
    title: {
      display: true,
      text: "Uczestnicy finałów w podziale na okręgi",
    },
  },
};

export const data = {
  labels: [
    "KO Poznań",
    "KO Toruń",
    "KO Warszawa",
    "KO Lublin",
    "KO Wrocław",
    "KO Gdańsk",
  ],
  datasets: [
    {
      label: "liczba uczestników",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "rgba(255, 99, 132, 0.8)",
        "rgba(54, 162, 235, 0.8)",
        "rgba(255, 206, 86, 0.8)",
        "rgba(75, 192, 192, 0.8)",
        "rgba(153, 102, 255, 0.8)",
        "rgba(255, 159, 64, 0.8)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ],
};
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);

export default function InterestingFacts(props) {
  const classData = {
    labels: props.chartData.participants_by_class.labels,
    datasets: [
      {
        label: "liczba uczestników",
        data: props.chartData.participants_by_class.data,
        backgroundColor: [
          "rgba(255, 99, 132, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(153, 102, 255, 0.8)",
          "rgba(255, 159, 64, 0.8)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const sexData = {
    labels: props.chartData.participants_by_sex.labels,
    datasets: [
      {
        label: "liczba uczestników",
        data: props.chartData.participants_by_sex.data,
        backgroundColor: [
          "rgba(255, 99, 132, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(153, 102, 255, 0.8)",
          "rgba(255, 159, 64, 0.8)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
      align="center"
      spacing={3}
    >
      <Grid item xs={12} sx={{ mt: 3 }}>
        <Typography variant="h4">Statystyki</Typography>
        {/* <Typography>
          <ul>
            {props.interestingFacts.map((fact) => (
              <li>{fact.text}</li>
            ))}
          </ul>
        </Typography> */}
      </Grid>
      <Grid item xs={12} md={4} sx={{ mt: 4 }} height="400px">
        <Pie data={classData} options={PieOption} height="50%" />
      </Grid>
      <Grid item xs={12} md={4} sx={{ mt: 4, pr: 4, pl: 2 }} height="400px">
        <Bar options={BarOption} data={sexData} height="50%" />
      </Grid>
      {/* <Grid item xs={12} md={4} sx={{ mt: 4, pr: 5 }} height="400px">
        <Bar options={BarOption2} data={data} height="50%" />
      </Grid> */}
    </Grid>
  );
}
