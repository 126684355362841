import { ToolBar } from "../components/Toolbar";
import "./App.css";
import { Box, Grid } from "@mui/material";
import "./Map.css";
import OlympiadHeader from "../components/OlympiadPage/OlympiadHeader";
import PageHeader from "../components/PageHeader";
import QuotationSlider from "../components/OlympiadPage/QuotationSlider";
import OlympiadDescription from "../components/OlympiadPage/OlympiadDescription";
import FinalStageInfo from "../components/OlympiadPage/FinalStageInfo";
import Artifacts from "../components/OlympiadPage/Artifacts";
import InterestingFacts from "../components/OlympiadPage/IntrestingFacts";
import ImageGallery from "../components/OlympiadPage/ImageGallery";
import Questions from "../components/OlympiadPage/Questions";
import Animation from "../components/Animation";
import { useEffect, useState, useRef } from "react";
import { toRomanNumber } from "../consts";
import axios from "axios";
import { path } from "../consts";
import { useParams } from "react-router-dom";
import Loader from "../components/Loader";
import axiosRetry from 'axios-retry';

import Footer from "../components/Footer";
import Results from "../components/OlympiadPage/Results";

axiosRetry(axios, { retries: 3 });



function OlympiadPage(props) {
  let { OlympiadId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [olympiadInfo, setOlympiadInfo] = useState(null);
  const dataFetchedRef = useRef(null);

  useEffect(() => {
    if (dataFetchedRef.current === OlympiadId) return;
    dataFetchedRef.current = OlympiadId
    setIsLoading(true)
    const fetchData = async () => {
      axios
        .get(`${path}/competition/details/${OlympiadId}`)
        .then((res) => {
          setOlympiadInfo(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    };

    fetchData();
  }, [OlympiadId]);

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="App">
          <Box sx={{ position: "sticky", top: 0, zIndex: 99999 }}>
            <ToolBar ></ToolBar>
          </Box>
          <PageHeader/>
          <OlympiadHeader
            editionNumberRoman={
              toRomanNumber[olympiadInfo.competition_base_info.edition_number]
            }
            editionNumber={olympiadInfo.competition_base_info.edition_number}
            schoolYear={olympiadInfo.competition_base_info.school_year}
          />
          <Grid container sx={{ p: 3 }} >
            <Grid item xs={12} sx={{ mt: 8 }}  >
              <Animation
                component={
                  <OlympiadDescription
                    motto={olympiadInfo.competition_base_info.motto}
                    img={olympiadInfo.competition_base_info.main_image}
                    description={olympiadInfo.competition_base_info.description}
                    firstStageTopics={olympiadInfo.first_stage_topics}
                  />
                }
              />
            </Grid>
            {
              OlympiadId != 46 &&
                <Grid item xs={12} sx={{ mt: 20 }}>
                <Animation
                  component={
                    <FinalStageInfo
                      dateFinalsStart={
                        olympiadInfo.finals_details.dates.date_start
                      }
                      dateFinalsEnd={olympiadInfo.finals_details.dates.date_end}
                      hostSchools={olympiadInfo.finals_details.school_data}
                      hostSchoolAddresses={olympiadInfo.finals_details.school_address}
                      // overnightPlace={olympiadInfo2.overnightPlace}
                      nOfParticipants={
                        olympiadInfo.finals_details.participants_number
                      }
                      otherPoints={olympiadInfo.POIs}
                      // winner={olympiadInfo2.winner}
                      place={olympiadInfo.competition_base_info.host_cities}
                    />
                  }
                />
              </Grid>
            }
            <Grid
              item
              xs={12}
              marginTop={12}
              paddingLeft={{ md: 10, xs: 0 }}
              paddingRight={{ md: 10, xs: 0 }}
            >
              <Animation
                component={
                  <QuotationSlider quotations={olympiadInfo.memories} />
                }
              />
            </Grid>
            <Grid item xs={12}>
              { olympiadInfo.artifacts.length > 0 &&
                <Box sx={{ mt: 8 }}>
                  <Animation
                    component={<Artifacts artifacts={olympiadInfo.artifacts} />}
                  />
                </Box>
              }
            </Grid>
            <Grid item xs={12} sx={{ mt: 8, px: {md: 10, sx:2}}}>
              <Animation
                component={<ImageGallery images={olympiadInfo.gallery} />}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 15, px: {lg: 10, sx:2}}}>
              <Animation
                component={<Results results={olympiadInfo.results}/>}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 15 }}>
              <Animation
                component={
                  <InterestingFacts
                    interestingFacts={olympiadInfo.interesting_facts}
                    chartData={olympiadInfo.stats}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 15 }}>
              <Animation
                component={
                  <Questions
                    editionNumber={
                      toRomanNumber[
                        olympiadInfo.competition_base_info.edition_number
                      ]
                    }
                    questions={olympiadInfo.questions}
                  />
                }
              />
            </Grid>
          </Grid>
          <Footer/>
        </div>
      )}

    </div>
  );
}

export default OlympiadPage;
