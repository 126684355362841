import { Box, Typography, Grid } from "@mui/material";

export default function HistoryDescription(props) {
  const marginLeft = props.opposite ? 0 : 8;
  const marginRight = props.opposite ? 8 : 0;
  return (
    <Box
      sx={{
        px: { xs: 4, md: 10 },
        mb: 8,
        mt: 5,
        py: { xs: 0, lg: 5 },
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          bmd={3}
          display={!props.opposite || props.isSmallScreen ? "flex" : "none"}
          justifyContent="center"
          alignItems="center"
        >
          <Box
            sx={{ width: { xs: "300px",md:"420px", bmd:"320px", lg: "450px" }, borderRadius: "15px" }}
            component="img"
            alt=""
            src={props.photo}
          />
        </Grid>
        <Grid item xs={12} bmd={9}>
          <Typography
            variant={props.isSmallScreen ? "h5" : "h4"}
            sx={{
              ml: { bmd: marginLeft },
              textAlign: "center",
              mt: { xs: 5, bmd: 0 },
            }}
          >
            {props.title}
          </Typography>
          {props.text.map((paragraph) => (
            <Typography
              sx={{
                // textIndent: "5rem",
                textAlign: "justify",
                mt: 4,
                ml: { xs: 0, bmd: marginLeft },
                mr: { xs: 0, bmd: marginRight },
              }}
            >
              {paragraph}
            </Typography>
          ))}
        </Grid>
        <Grid
          item
          md={3}
          display={props.opposite && !props.isSmallScreen ? "flex" : "none"}
          justifyContent="center"
          alignItems="center"
        >
          <Box
            sx={{ width:{ xs: "300px", md:"420px", bmd:"320px", lg: "450px" }, borderRadius: "15px" }}
            component="img"
            alt=""
            src={props.photo}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
