import {
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Popover,
  TextField,
  Tooltip,
  Grid,
  Checkbox,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState } from "react";
import { toRomanNumber } from "../consts";

export default function ArtifactsSelectionBar(props) {
  const [openPopover, setOpenPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  function handleListClick(shouldEraseInput, setStateFunction, value) {
    if (shouldEraseInput) props.setSearchPhrase("");
    setStateFunction((prevValue) => {
      const index = prevValue.indexOf(value);
      let newValue = JSON.parse(JSON.stringify(prevValue));
      if (index > -1) newValue.splice(index, 1);
      else newValue.push(value);
      return newValue;
    });
  }

  function handleSelectAll(
    shouldEraseInput,
    setStateFunction,
    allValuesPacked
  ) {
    if (shouldEraseInput) props.setSearchPhrase("");
    const allValues = allValuesPacked.map((item) => item.id);
    setStateFunction((prevValue) => {
      if (prevValue.length !== allValues.length) return allValues;
      else return [];
    });
  }

  return (
    <Grid container p={5} spacing={0}>
      <Grid item xs={10} bmd={4}>
        <TextField
          sx={{ mb: 3 }}
          fullWidth
          label="Wyszukaj"
          variant="outlined"
          onChange={(e) => {
            props.setSearchPhrase(e.target.value);
          }}
          value={props.searchPhrase}
        />
      </Grid>
      <Grid item xs={0.5}>
        <Tooltip title="Wyszukiwanie możliwe jest po numerze olimpiady (cyfry rzymskie), miejscowości i nazwisku uczestnika">
          <IconButton>
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={12} bmd={7}>
        <FormControl component="fieldset">
          <FormGroup row={!props.isSmallScreen}>
            <FormControlLabel
              control={<Checkbox />}
              label="Wszystkie rodzaje"
              onClick={(e) => {
                e.preventDefault();
                handleSelectAll(
                  false,
                  props.setSelectedTypes,
                  props.artifactsTypes
                );
              }}
              checked={
                props.selectedTypes.length === props.artifactsTypes.length
              }
            />
            {props.artifactsTypes.map((type) => (
              <FormControlLabel
                control={<Checkbox />}
                key={type.id}
                label={type.name}
                checked={props.selectedTypes.includes(type.id)}
                onClick={(e) => {
                  e.preventDefault();
                  handleListClick(false, props.setSelectedTypes, type.id);
                }}
              />
            ))}
          </FormGroup>
        </FormControl>

        <IconButton
          onClick={(event) => {
            setOpenPopover(true);
            setAnchorEl(event.currentTarget);
          }}
          variant="contained"
        >
          <MoreVertIcon />
        </IconButton>
        <Popover
          open={openPopover}
          anchorEl={anchorEl}
          onClose={() => {
            setOpenPopover(false);
            setAnchorEl(null);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          PaperProps={{
            style: { width: { lg: "10%", xs: "30%" }, height: "50%" },
          }}
        >
          <FormControl component="fieldset" sx={{ p: 4 }}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                label="Wszystkie edycje"
                checked={
                  props.selectedEditions.length ===
                  props.competitionEditionList.length
                }
                onClick={(e) => {
                  e.preventDefault();
                  handleSelectAll(
                    true,
                    props.setSelectedEditions,
                    props.competitionEditionList
                  );
                }}
              />
              {props.competitionEditionList.map((edition) => (
                <FormControlLabel
                  control={<Checkbox />}
                  label={
                    toRomanNumber[edition.edition_number] + " " + edition.type
                  }
                  checked={props.selectedEditions.includes(edition.id)}
                  key={edition.id}
                  onClick={(e) => {
                    e.preventDefault();
                    handleListClick(
                      true,
                      props.setSelectedEditions,
                      edition.id
                    );
                  }}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Popover>
      </Grid>
    </Grid>
  );
}
