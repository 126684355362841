import { Typography, Grid, Box } from "@mui/material";

export default function OlympiadDescription(props) {
  return (
    <div>
      <Grid container sx={{px: {sx: 2, md: 8},}}>
        <Grid item xs={12} bmd={4}>
          <Box
            sx={{
              mt: 1,

              width: "70%",
              borderRadius: "10px",
              justifyContent: "left",
              alignItems: "left",
              objectFit: "cover",
              objectPosition: "top",
              maxHeight: "300px",
            }}
            component="img"
            src={props.img}
          />
        </Grid>
        <Grid item xs={12} bmd={8} sx={{ mt: 2 }}>
          { props.motto &&
            <Typography variant="h5">
              <i>{props.motto}</i>
            </Typography>
          }
          <Typography sx={{ mt: 2 }} textAlign={'justify'}>{props.description}</Typography>
          <Typography
            sx={{
              mt: 2,
            }}
          >
            <Typography>
              <b>Tematy prac I etapu: </b>
            </Typography>
            {props.firstStageTopics.map((topic) => (
              <Typography textAlign={'left'}>
                <b>{topic.symbol}.</b> {topic.text}
              </Typography>
            ))}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
