import { useNavigate } from "react-router";
import { Box, Button, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

export default function PageHeader(props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("bmd"));

  return (
    <div>
      <Box
        sx={{
          height: { bmd: 250, xs: 200 },
          width: "100%",
          overflow: "hidden",
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: {xs: "100%"},
            // top: -300,
            // right: 200,
            opacity: 0.8,
            zIndex: 1,
          }}
          component="img"
          alt="Header image"
          src={isMobile ? "/headbar_small.jpg" : "/headbar_big.png"}
        />
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Button
          onClick={() => navigate("/")}
          sx={{
            "&:hover": {
              backgroundColor: "white",
            },
            mt: { bmd: -9, xs: -7 },
            height: { bmd: 170, xs: 130 },
            width: { bmd: 170, xs: 130 },
            borderRadius: "50%",
            backgroundColor: "white",
            zIndex: 900,
            position: "absolute",
          }}
        >
          <Box
            sx={{
              height: { bmd: 150, xs: 110 },
              width: { bmd: 150, xs: 110 },
              borderRadius: "50%",
            }}
            component="img"
            alt="logo"
            src="/logo.ico"
          />
        </Button>
      </Box>
    </div>
  );
}
