import ApartmentIcon from "@mui/icons-material/Apartment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import HotelIcon from "@mui/icons-material/Hotel";
import SchoolIcon from "@mui/icons-material/School";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { Typography, Grid, Button } from "@mui/material";
import FinalStageMap from "./FinalStageMap";
import { useState } from "react";

export default function FinalStageInfo(props) {
  const [scale, setScale] = useState("city");
  return (
    <div>
      <Grid container>
        <Grid item xs={12} bmd={4} sx={{ mt: 2 }}>
          <Typography variant="h4">Zawody finałowe</Typography>
          <Typography fontSize={18}>
            <Grid container>
              <Grid item xs={12} marginTop={3}>
                <CalendarMonthIcon/>{" "}
                {String(new Date(props.dateFinalsStart).getDate()).padStart(
                  2,
                  "0"
                )}
                .
                {String(
                  new Date(props.dateFinalsStart).getMonth() + 1
                ).padStart(2, "0")}
                -
                {String(new Date(props.dateFinalsEnd).getDate()).padStart(
                  2,
                  "0"
                )}
                .
                {String(new Date(props.dateFinalsEnd).getMonth() + 1).padStart(
                  2,
                  "0"
                )}
                .{new Date(props.dateFinalsEnd).getFullYear()}r.
              </Grid>
              <Grid item xs={12} marginTop={2}>
                <SchoolIcon /> {props.hostSchools[0].name}
                {props.hostSchools.length > 1 && ` / ${props.hostSchools[1].name}`
                }
              </Grid>
              {/* <Grid item xs={12} marginTop={1}>
                <HotelIcon /> {props.overnightPlace.name} 
              </Grid> */}
              <Grid item xs={12} marginTop={2}>
                <ApartmentIcon /> {props.place[0]}
                {props.place.length > 1 && props.place[0] !== props.place[1] && ` / ${props.place[1]}`
                } &emsp;&emsp;
                <PeopleAltIcon /> {props.nOfParticipants} osób
              </Grid>
              {/* <Grid item xs={12} marginTop={1} marginBottom={3}>
                <EmojiEventsIcon /> {props.winner.name} {props.winner.surname},{" "}
                <br />
                {props.winner.school}, {props.winner.schoolPlace}, nauczyciel{" "}
                {props.winner.teacher.title} {props.winner.teacher.name}{" "}
                {props.winner.teacher.surname}
              </Grid> */}
            </Grid>
          </Typography>
          <Button
            variant="contained"
            sx={{ mr: { bmd: 10, xs: 2 }, mt: 5, }}
            onClick={() => setScale("country")}
          >
            W skali kraju
          </Button>
          <Button
            variant="contained"
            sx={{ mt: 5, mr: { bmd: 0, xs: 2 }}}
            onClick={() => setScale("city")}
          >
            W skali miasta
          </Button>
        </Grid>
        <Grid item xs={12} bmd={8} sx={{mt: 2, mx:{xs: 0, md: 10, bmd: 0}}}>
          <FinalStageMap
            hostSchoolAddresses={props.hostSchoolAddresses}
            hostSchools={props.hostSchools}
            // overnightPlace={props.overnightPlace}
            otherPoints={props.otherPoints}
            scale={scale}
          />
        </Grid>
      </Grid>
    </div>
  );
}
