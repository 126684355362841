import React from "react";
import { useDroppable } from "@dnd-kit/core";
import { Typography } from "@mui/material";

export function Droppable(props) {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
  });
  const style = {
    opacity: isOver ? 1 : 0.5,
    borderStyle: "solid",
    height: props.size,
    width: 270,
    marginTop: 10,
    marginLeft: "auto",
    marginRight: "auto",
  };

  return (
    <div ref={setNodeRef} style={style}>
      <Typography> {props.text}</Typography>
      {props.children}
    </div>
  );
}
