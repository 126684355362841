import React from "react";
import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { Button } from "@mui/material";

export function Draggable(props) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.id,
    disabled: props.disabled, 
  });
  const style = {
    transform: CSS.Translate.toString(transform),
    ...props.style,
  };

  return (
    <Button
      variant="contained"
      sx={{ mt: 4, mr: 2, maxWidth:'200px' }}
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
    >
      {props.children}
    </Button>
  );
}
