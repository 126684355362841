import { Box, Dialog, Fab, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import YouTube from 'react-youtube';

export default function PhotoDialog(props) {
  return (
    <Dialog
      open={props.isPhotoOpen}
      onClose={props.onClose}
      sx={{
        // height: '100%',
        pt: 10,
        // mb: 10,
      }}
    >

      <div>
        {(props.image && props.image[0] === '.') ? 
        <Box component="img" src={props.image} sx={{height: '100%', width: '100%'}}/> :
        <YouTube opts={{width: '550px', height: "320px"}} videoId="_fJMPvwEZNE"/>}
      </div>

      <Box display='flex' alignItems='center' justifyContent='center' flexDirection='row'>
      {!props.isFirst &&
          <Fab
            size='small'
            sx={{
                mx: 1,
                bgcolor: '#224080',
                color: 'white',
                '&:hover': { bgcolor: '#6b8bd1' },
            }}
            component="label"
            onClick={props.handlePrevClick}
          >
            <KeyboardArrowLeftIcon sx={{ fontSize: 20 }} />
          </Fab>}
        {props.description && <Typography sx={{my: 2, textAlign: 'center'}}>{props.description  }</Typography>}
        {!props.isLast &&
        <Fab
            sx={{
                mx: 1,
                bgcolor: '#224080',
                color: 'white',
                '&:hover': { bgcolor: '#6b8bd1' },
            }}
            component="label"
            onClick={props.handleNextClick}
            size='small'
          >
            <KeyboardArrowRightIcon sx={{ fontSize: 20 }} />
        </Fab>}
      </Box>
    </Dialog>
  );
}
