import { Box, Button, Grid, Typography } from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { toRomanNumber } from "../consts";
import { useNavigate } from "react-router";

export default function CompetitionsOfTheWeek(props) {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      justifyContent='center'
      alignItems='center'
      sx={{
        pl: 2,
        pb: 2,
        mt: 5, 
        mx: {xs: 2, md: 10, bmd: 5, lg: 10}
        // width: "90%",
      }}
    >
      <Grid container spacing={5}>
        {props.competitionsOfTheWeek.map((competition) => (
          <Grid item xs={12} bmd={6} key={competition.id}>
            <Grid container spacing={0}>
              <Grid item xs={12} alignItems={"left"}>
                <Typography fontSize={30} justifyContent="left">
                  <b>
                    {toRomanNumber[competition.edition_number]}{" "}
                    {competition.type}
                  </b>
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    mt: 3,
                    width: { xs: "50%", md: "100%" },
                    borderRadius: "10px",
                    height: "220px",
                    minWidth: '80%',
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                  component="img"
                  src={competition.main_image}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Grid container flexDirection={"column"}>
                  <Grid item xs={12}>
                    <Typography sx={{ mt: 2 }}>
                      <ApartmentIcon
                        sx={{ position: "relative", top: 5, ml: 1 }}
                      />
                      {competition.host_cities[0]}
                      <CalendarMonthIcon
                        sx={{ position: "relative", top: 5, ml: 1 }}
                      />
                      {competition.school_year}
                    </Typography>
                    <Typography
                      sx={{ mt: 2, ml: 4, mr: 4 }}
                      justifyContent={"left"}
                      textAlign={'justify'}
                    >
                      {competition.description.slice(0, competition.description.slice(0, 400).lastIndexOf(' '))} ...
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => {
                        navigate(`/olimpiada/${competition.id}`);
                        window.scrollTo(0, 0);
                      }}
                    >
                      Dowiedz się więcej...
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
