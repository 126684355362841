import { Box, TextField, Typography } from "@mui/material";

export default function OpenQuestion(props) {
  return (
    <div>
      <Typography><b>{props.text}</b></Typography>
      <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' marginTop={2}> 
        <Typography sx={{mr:0.5}}>{props.preAnswer}</Typography>
        <TextField
          value={
            props.correctAnswer
              ? `${props.selectedAnswer} (${props.correctAnswer})`
              : props.selectedAnswer
          }
          disabled={props.checkRequested || props.disabled}
          onChange={(e) => props.handleInputChange(e.target.value, props.id)}
        ></TextField>
        <Typography sx={{ml:0.5}}>{props.postAnswer}</Typography>
      </Box>
    </div>
  );
}
