import { Box, Button, Typography, colors } from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function ChoiceSingleLongQuestion(props) {
  return (
    <Box
        display='flex'
        flexDirection='column'
        justifyContent="center"
        alignItems="center"
        textAlign='left'
         >
      <Typography sx={{ mt: 2}}><b>{props.questionText}</b></Typography>
      <Box width='30%'
        >
        <FormControl >
          <RadioGroup
            value={props.selectedAnswers[0] ? props.selectedAnswers[0] : null }
            onClick={(e) => {
              if (!props.disabled && !props.checkRequested && e.target.value){
                props.selectAnswer(props.id, parseInt(e.target.value));
              }
            }}
          >
            {props.answers.map((answer) => {
              let style = {};
              if (props.correctAnswer) {
                if (props.correctAnswer.includes(answer.id))
                  style.color = "green";
                else if (
                  props.selectedAnswers.includes(answer.id) &&
                  !props.correctAnswer.includes(answer.id)
                )
                  style.color = "red";
              }
              
              return (
                  <FormControlLabel style={style} value={answer.id} control={<Radio style={style}/>} 
                   label={answer.text} sx={{my: 2}}/>
              )
            })}
            
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
}
