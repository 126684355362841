import { Box, Checkbox, Typography } from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';

export default function ChoiceMultipleLongQuestion(props) {
  return (
    <Box         display='flex'
    flexDirection='column'
    justifyContent="center"
    alignItems="center">
      <Typography sx={{ mt: 2 }}><b>{props.questionText} </b></Typography>
      <Box width='30%'>
            {props.answers.map((answer) => {
              let style = {};
              if (props.correctAnswer) {
                if (props.correctAnswer.includes(answer.id))
                  style.color = "green";
                else if (
                  props.selectedAnswers.includes(answer.id) &&
                  !props.correctAnswer.includes(answer.id)
                )
                  style.color = "red";
              }

              return (
                  <FormControlLabel
                    style={style}
                    value={answer.id} 
                    control={
                      <Checkbox 
                        style={style}
                        iconStyle={style}
                        onChange={ () => {
                          if (!props.disabled && !props.checkRequested) props.selectAnswer(props.id, answer.id);
                        }}
                        checked={props.selectedAnswers.includes(answer.id)} 
                      />}
                    label={answer.text}
                    sx={{my: 2}}
                  />
              )
            })}
      </Box>
    </Box>
  );
}
