import { ToolBar } from "../components/Toolbar";
import { Box, Typography, useMediaQuery } from "@mui/material";
import PageHeader from "../components/PageHeader";
import "./History.css";
import Animation2 from "../components/Animation2";
import HistoryDescription from "../components/HistoryDescription";
import { useTheme } from "@emotion/react";
import Footer from "../components/Footer";

const historyData = [
  {
    title: "Powstanie i początki",
    text: [
      `Olimpiada Geograficzna została powołana do życia w 1974 roku z inicjatywy prof. Anny
    Dylikowej. Jej pomysł zyskał poparcie Zarządu Głównego Polskiego Towarzystwa
    Geograficznego, a szczególnie jego Przewodniczącego prof. Stanisława Berezowskiego. Na
    wniosek Zarządu Głównego PTG, Minister Oświaty i Wychowania w 1974 r. powołał
    Olimpiadę Geograficzną oraz jej Komitet Główny, któremu powierzył całokształt spraw
    związanych z organizacją zawodów. Komitet Główny opracował ogólną koncepcję wymagań
    stawianych olimpijczykom oraz przystąpił do organizacji zawodów. Zawody finałowe I Olimpiady odbyły się w 1975 r. w Łodzi. W kolejnych latach zawody
    finałowe miały miejsce w różnych miastach Polski, dając uczestnikom zawodów możliwość odwiedzenia
    wielu części kraju — co stało się tradycją Olimpiady.`,
      `Grono założycieli Olimpiady Geograficznej na poziomie Komitetu Głównego stanowili: prof.
    dr hab. Anna Dylikowa, Elżbieta Lewandowska, mgr Maria Szemeta, dr Danuta Licińska,
    mgr Irena Berne, dr Maria M. Wilczyńska-Wołoszyn, prof. dr hab. Jan Flis, prof. dr hab.
    Maria Czekańska, mgr Lech Dmowski, prof. dr hab. Józef Barbag. Prof. Anna Dylikowa kierowała pracami Komitetu Głównego od samego początku, aż do
    swojej tragicznej śmierci w 2000 r. Prace Komitetu Głównego od samego początku były wspierane przez Komitety Okręgowe. W
    czasie I OG istniało 19 Komitetów Wojewódzkich. Od kolejnej edycji powołano 11
    Komitetów Okręgowych, zogniskowanych wokół ośrodków akademickich i działających na
    obszarach bazujących na ówcześnie obowiązującym podziale administracyjnym.`,
    ],
    image: "./photos/history/description/beginning.png",
    opposite: false,
  },
  {
    title: "Olimpiada Geograficzna i Olimpiada Nautologiczna",
    text: [
      `Od 1991 roku zakres Olimpiady uległ rozszerzeniu. Na skutek starań organizatorów konkursu
    „Młodzież na Morzu” o nadanie temu konkursowi rangi olimpiady, Ministerstwo Edukacji
    Narodowej zwróciło się do Komitetu Głównego Olimpiady Geograficznej z prośbą o
    rozważenie możliwości włączenia poszerzonej problematyki morskiej do zadań Olimpiady
    Geograficznej. Olimpiada Nautologiczna została powołana w 1991 r., co zdecydowało to o
    poszerzeniu formuły zawodów. W pierwszych latach wspólnego funkcjonowania Olimpiady
    Geograficznej i Nautologicznej uczniowie zakwalifikowani do zawodów finałowych
    decydowali się na start w zawodach jednej z Olimpiad. Począwszy od roku 1997 uczniowie
    mieli możliwość startu w zawodach finałowych obydwu Olimpiad.`,
      `W 1999 r. dostosowano strukturę organizacyjną Olimpiady do nowego podziału
      administracyjnego Polski. Do roku 2016 funkcjonowało 12 Komitetów Okręgowych. Po śmierci prof. dr hab. Anny Dylikowej,
      pracami Komitetu Głównego w latach 2000-2005 kierował prof. dr hab. Wojciech Stankowski, następnie w latach 2005-2015 prof. dr hab.
      Teresa Madeyska. W efekcie zmiany zasad organizacji olimpiad przedmiotowych, w 2010 roku w Supraślu po
      raz ostatni odbyły się wspólne zawody finałowe XXXVI Olimpiady Geograficznej i XIX
      Olimpiady Nautologicznej.`,
    ],
    image: "./photos/history/description/ON.png",
    opposite: true,
  },
  {
    title: "Rywalizacja Międzynarodowa",
    text: [
      `Z inicjatywy holenderskich i polskich geografów, ze szczególnym udziałem dr Marii M. Wilczyskiej-Wołoszyn, podczas Kongresu Międzynarodowej Unii Geograficznej w Pradze w 1994 zdecydowano o powołaniu Międzynarodowej Olimpiady Geograficzej (iGeo). Drużyna z naszego kraju bierze udział w zawodach od I iGeo, która odbyła się w 1996 w Hadze, będąc jedną z trzech, która wzięła udział we wszystkich edycjach. W latach 2003-2011, co 2 lata odbywały się Środkowoeuropejskie Olimpiady Geograficzne, a począwszy od 2015 roku, polscy uczniowie uczestniczą w zawodach Bałtyckiej Olimpiady Geograficznej.
      Na przestrzeni lat, polscy uczniowie dowiedli w zawodach iGeo bardzo wysokiego poziomu polskiej geografii szkolnej, 7-krotnie zwyciężając w klasyfikacji drużynowej, 4-krotnie będąc najlepsi indywidualnie, a także przywożąc 16 złotych, 22 srebrne i 15 brązowych medali (przyznawane są od 2004 roku).
      Polska była gospodarzem zawodów Międzynarodowej Olimpiady Geograficznej w 2004 (w Gdyni) i w 2014 (w Krakowie). Gościliśmy również zawody Środkowoeuropejskiej Olimpiady Geograficznej w roku 2009 w Chorzowie i Bielsku-Białej, a także zawody Bałtyckiej Olimpiady Geograficznej w 2021 roku w Suwałkach.`,
    ],
    image: "./photos/history/description/iGeo.jpg",
    opposite: false,
  },
  {
    title: "Olimpiada Geograficzna po zmianach",
    text: [
      `Od 2011 r., przywrócony został dawny format Olimpiady Geograficznej oraz pierwotna
    nazwa Komitetu Głównego. W roku 2012 przeprowadzono pierwszy drużynowy konkurs terenowy, stanowiący
      uzupełnienie testów olimpijskich o umiejętności pracy w terenie wymagane od uczestników
      zawodów międzynarodowych. Konkurs ten nie wlicza się do wyników indywidualnych
      zawodów finałowych, niemniej cieszy się bardzo dużą popularnością wśród uczestników.
      W 2016 roku wydzielono dwa nowe Komitety Okręgowe (z siedzibami w Rzeszowie i
        Kielcach), rozszerzając ich listę do czternastu. Przewodniczącym Komitetu Głównego od roku 2015 został dr hab. Zbigniew Podgórski.
        Za całokształt prac organizacyjnych od początku Olimpiady odpowiada kierownik
        organizacyjny (koordynator projektu). W 1974 to zadanie powierzono dr Marii M. Wilczyńskiej-Wołoszyn. W 1984 kierownikiem została mgr Małgorzata Sikorska, która sprawowała tę
        funkcję przez kolejne 32 lata. Od 2016 koordynatorem jest mgr Tomasz Sawicki.`,
      `Pandemia COVID-19, która zaczęła się w 2020, była powodem pierwszego w historii
        Olimpiady roku, w którym nie odbyły się zawody finałowe, a tytuły finalistów przyznano
        bazując na wynikach zawodów okręgowych. W 2021 roku, działając we wciąż pandemicznych
        warunkach, zawody były okrojone o część ustną, a finały zostały przeprowadzone w 14
        miejscach w kraju.`,
    ],
    image: "./photos/history/description/zmiany.png",
    opposite: true,
  },
  {
    title: "Formuła zadań",
    text: [
      `Od początku działania Olimpiada miała charakter trójetapowy. I etap Olimpiady polega na
    przygotowaniu pisemnego opracowania na jeden z zaproponowanych tematów,
    wymagających dokonania przez uczniów samodzielnych badań. W latach 2007-2010 prace I etapu na
    wybrane tematy można było przygotować w formie posteru. Zawody II i III stopnia składają się z części pisemnej - kilku testów o zróżnicowanej
      tematyce pokrywającej wszystkie elementy geografii fizycznej oraz społeczno-ekonomicznej. 
      Najlepsi uczniowie w zawodach pisemnych kwalifikowani są do tzw. części ustnej.
      Początkowo część ustna zarówno II, jak i III etapu składała się z dwóch lub trzech
      odpowiedzi ustnych na wylosowane pytanie. Począwszy od roku 1997 zawody finałowe
      obejmują jedną część ustną i quiz geograficzny. Podobną formułę przyjęto dla zawodów
      ustnych podczas II etapu począwszy od roku 2007. `,
      `Za opracowanie zadań odpowiada Komisja Zadań powoływana przez Komitet Główny
      Olimpiady Geograficznej. Pierwszym Przewodniczącym Komisji Zadań, który nadał ton
      typowi zadań olimpijskich był prof. dr hab. Jan Flis (1974-1985). Następnie, Komisja Zadań
      pracowała pod kierownictwem prof. dr hab. Teresy Madeyskiej (1985-2005), dr Bożena
      Kicińska (2005-2009), dr Ryszard Szczęsny (2009-2013), prof. dr hab. Florian Plit (2013-
      2016), a od 2016 roku prof. dr hab. Przemysław Śleszyński.`,
    ],
    image: "./photos/history/description/formula.png",
  },
];

const timeline = [
  {
    timePoint: "1974/75",
    text: "Powołanie Olimpiady Geograficznej, zawody finałowej I Olimpiady Geograficznej w Łodzi",
    image: "./photos/history/timeline/beginning.png",
  },
  {
    timePoint: "1975/76",
    text: "Utworzenie struktury 11 Komitetów Okręgowych",
    image: "./photos/history/timeline/okregi1.png",
  },
  {
    timePoint: "1991",
    text: "Powołanie Olimpiady Nautologicznej i włączenie jej organizacyjnie w zawody Olimpiady Geograficznej",
    image: "./photos/history/timeline/ON.png",
  },
  {
    timePoint: "1996",
    text: "Pierwsza Międzynarodowa Olimpiada Geograficzna w Hadze (zwycięstwo polskiej drużyny)",
    image: "./photos/history/timeline/igeo.jpg",
  },
  {
    timePoint: "1999/2000",
    text: "Dostosowanie struktury organizacyjnej do zmian administracyjnych Polski - 12 Komitetów Okręgowych",
    image: "./photos/history/timeline/okregi2.png",
  },
  {
    timePoint: "2002",
    text: "Ustanowienie Odznaki Profesor Anny Dylikowej",
    image: "./photos/history/timeline/odznaka.jpg",
  },
  {
    timePoint: "2004",
    text: "Organizacja w Gdyni V Międzynarodowej Olimpiady Geograficznej",
    image: "./photos/history/timeline/gdynia.png",
  },
  {
    timePoint: "2009",
    text: "Organizacja w Bielsku-Białej i Chorzowie IV Środkowoeuropejskiej Olimpiady Geograficznej",
    image: "./photos/history/timeline/CERIGEO.png",
  },
  {
    timePoint: "2010",
    text: "Ostatnie wspólne zawody Olimpiady Geograficznej i Olimpiady Nautologicznej",
    image: "./photos/history/timeline/last_nauto.png",
  },
  {
    timePoint: "2012",
    text: "Pierwszy konkurs terenowy podczas zawodów finałowych",
    image: "./photos/history/timeline/terenowe.jpg",
  },
  {
    timePoint: "2014",
    text: "Organizacja w Krakowie XI Międzynarodowej Olimpiady Geograficznej",
    image: "./photos/history/timeline/krakow.jpg",
  },
  {
    timePoint: "2016/2017",
    text: "Powołanie Komitetów Okręgowych w Kielcach i w Rzeszowie",
    image: "./photos/history/timeline/okregi3.png",
  },
  {
    timePoint: "2020",
    text: "Pandemia COVID—19, jedyny rok bez zawodów finałowych",
    image: "./photos/history/timeline/pandemia.png",
  },
  {
    timePoint: "2021",
    text: "Organizacja w Suwałkach VII Bałtyckiej Olimpiady Geograficznej",
    image: "./photos/history/timeline/suwalki.png",
  },
  {
    timePoint: "2023/2024",
    text: "50. edycja Olimpiady Geograficznej",
    image: "./photos/history/timeline/50-lat.png",
  },
];

function Hisotry() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("bmd"));
  return (
    <div>
      <Box sx={{ position: "sticky", top: 0, zIndex: 99999 }}>
        <ToolBar></ToolBar>
      </Box>
      <PageHeader />
      <Typography
        display="flex"
        justifyContent="center"
        alignItems="center"
        variant={isMobile ? "h4" : "h2"}
        sx={{ mt: {bmd: 10, xs: 7}, mb: 5 }}
      >
        Historia Olimpiady
      </Typography>

      {historyData.map((data) => (
        <Animation2
          key={0}
          component={
            <HistoryDescription
              isSmallScreen={isMobile}
              photo={data.image}
              text={data.text}
              title={data.title}
              opposite={data.opposite}
            />
          }
        />
      ))}

      <Typography
        variant={isMobile ? "h5" : "h4"}
        sx={{
          my: 15,
          textAlign: "center",
        }}
      >
        {" "}
        Najważniejsze wydarzenia z historii Olimpiady
      </Typography>
      <Box sx={{display: 'flex', justifyContent:'center', alignItems: 'center'}}>
      <div class="styled-list">
        <ul>
          {timeline.map((point) => (
            <li>
              <Animation2
                component={
                  <div class="grid-container">
                    <div class="date">{point.timePoint}</div>
                    <div class="image">
                      <Box
                        component="img"
                        alt=""
                        src={point.image}
                        sx={{
                          width: {xs:'250px', sm:'350px', bmd:'auto'},
                          height: {xs: 'auto', bmd:"100%"},
                          maxHeight: {xs: "1000px", bmd: '170px', lg: "230px" },
                          mt: "-2rem",
                          borderBottomLeftRadius: { bmd: "15px" },
                          
                        }}
                      ></Box>
                    </div>
                    <div class="descr">
                      <Typography
                        sx={{
                          fontSize: {xs: 17, sm:20, bmd: 15, lg: 20},
                          textAlign: "center",
                          mr: 5,
                          ml: 5,
                        }}
                      >
                        {point.text}
                      </Typography>
                    </div>
                  </div>
                }
              />
            </li>
          ))}
        </ul>
      </div>
      </Box>
      <Footer/>
    </div>
  );
}

export default Hisotry;
