import { Box, Button, Typography } from "@mui/material";

export default function ChoiceQuestion(props) {
  return (
    <div>
      <Typography sx={{ mt: 2 }}><b>{props.questionText}</b></Typography>
      <Box display="row">
        {props.answers.map((answer) => {
          let style = {};
          if (props.correctAnswer) {
            if (props.correctAnswer.includes(answer.id))
              style.backgroundColor = "green";
            else if (
              props.selectedAnswers.includes(answer.id) &&
              !props.correctAnswer.includes(answer.id)
            )
              style.backgroundColor = "red";
          }

          if (!props.selectedAnswers.includes(answer.id)) style.opacity = "0.5";

          return (
            <Button
              style={style}
              variant="contained"
              sx={{ ml: 2, mt: 2 }}
              onClick={() => {
                if (!props.disabled && !props.checkRequested) props.selectAnswer(props.id, answer.id);
              }}
            >
              {answer.text}
            </Button>
          );
        })}
      </Box>
    </div>
  );
}
