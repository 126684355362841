import "swiper/css";
import { SwiperSlide, Swiper } from "swiper/react";
import { Button, Typography } from "@mui/material";
import { Navigation } from "swiper";
import "swiper/css/navigation";
import { useState } from "react";
export default function QuotationSlider(props) {

  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <Swiper
      modules={[Navigation]}
      spaceBetween={50}
      slidesPerView={1}
      onSlideChange={() => setIsExpanded(false)}
      navigation
    >
      {props.quotations.map((quote) => (
        <SwiperSlide>
          {isExpanded ? 
            quote.text.split('$').map((paragraph) => 
            <Typography sx={{ fontSize: { md: 20, xs: 15 }, mx:5, mt: 3 }}>
              <i>{paragraph}</i>
            </Typography>
            )
          :
          <Typography sx={{ fontSize: { md: 20, xs: 15 }, mx:5 }}>
            {quote.text.split('$')[0]} ...
          </Typography>

          }

          {quote.text.split('$').length > 1 &&
          <Button sx={{mt: 3}} onClick={() => setIsExpanded((prevState) => !prevState)}>
           {isExpanded? 'Zwiń' :  'Czytaj dalej'}
          </Button>}

          <Typography sx={{ mt: 1, mx:5,  display: "flex", justifyContent: "right" }}>
            <b>
              {quote.personal_data.first_name} {quote.personal_data.surname}
            </b>
            {/* , {quote.author.role} */}
          </Typography>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
