import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import { MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import { useNavigate } from "react-router-dom";

export function ToolBar(props) {
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const navigate = useNavigate();

  return (
    <div>
      <AppBar
        position="relative"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, mb: 0 }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", bmd: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
              >
                <MenuItem
                  key="history"
                  onClick={() => {
                    navigate("/historia");
                    window.scrollTo(0, 0);
                    handleCloseNavMenu();
                  }}
                >
                  <Typography textAlign="center">Historia Olimpiady</Typography>
                </MenuItem>
                <MenuItem
                  key="people"
                  onClick={() => {
                    navigate("/ludzie");
                    window.scrollTo(0, 0);
                    handleCloseNavMenu();
                  }}
                >
                  <Typography textAlign="center">
                    Oni tworzyli olimpiadę
                  </Typography>
                </MenuItem>

                <MenuItem
                  key="olympiads-list"
                  onClick={() => {
                    navigate("/olimpiada");
                    window.scrollTo(0, 0);
                    handleCloseNavMenu();
                  }}
                >
                  <Typography textAlign="center">Edycje Olimpiady</Typography>
                </MenuItem>
                <MenuItem
                  key="artifacts"
                  onClick={() => {
                    navigate("/pamiatki");
                    window.scrollTo(0, 0);
                    handleCloseNavMenu();
                  }}
                >
                  <Typography textAlign="center">
                    Olimpijskie pamiątki
                  </Typography>
                </MenuItem>
              </Menu>
              <Box width='100%' justifyContent='center' alignItems='center' textAlign='center'>
              <Button
                onClick={() => navigate("/")}
                sx={{
                  "&:hover": {
                    // backgroundColor: "white",
                  },
                  borderRadius: "50%",
                  // backgroundColor: "white",
                  mr: 6
                }}
              >
                <Box
                  sx={{
                    borderRadius: "50%",
                    width: '50px'
                  }}
                  component="img"
                  alt="The house from the offer."
                  src="/logo.ico"
                />
              </Button>
              </Box>
            </Box>
            {/* <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: "flex", lg: "none" } }}
            ></Typography> */}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", bmd: "flex" },
                width: "100%",
              }}
            >
              <Button
                onClick={() => navigate("/")}
                sx={{
                  "&:hover": {
                    // backgroundColor: "white",
                  },
                  borderRadius: "50%",
                  // backgroundColor: "white",
                  mr: 4
                }}
              >
                <Box
                  sx={{
                    borderRadius: "50%",
                    width: '50px'
                  }}
                  component="img"
                  alt="The house from the offer."
                  src="/logo.ico"
                />
              </Button>
              <Button
                key="olympiad-history"
                sx={{
                  my: 2,
                  mr: 4,
                  color: "white",
                }}
                onClick={() => {
                  navigate("/historia");
                  window.scrollTo(0, 0);
                }}
              >
                <b>Historia Olimpiady</b>
              </Button>
              <Button
                key="people-button"
                sx={{ my: 2, mr: 3, color: "white", display: "block" }}
                onClick={() => {
                  navigate("/ludzie");
                  window.scrollTo(0, 0);
                }}
              >
                <b>Oni tworzyli olimpiadę</b>
              </Button>
              <Button
                key="olympiad-list-button"
                sx={{ my: 2, mr: 3, color: "white", display: "block" }}
                onClick={() => {
                  navigate("/olimpiada");
                  window.scrollTo(0, 0);
                }}
              >
                <b>Edycje Olimpiady</b>
              </Button>

              <Button
                key="artifacts-button"
                sx={{ my: 2, mr: 3, color: "white", display: "block" }}
                onClick={() => {
                  navigate("/pamiatki");
                  window.scrollTo(0, 0);
                }}
              >
                <b>Olimpijskie pamiątki</b>
              </Button>
              <Button
                key="olympiad-page-button"
                sx={{
                  ml: "auto",
                  my: 2,
                  color: "white",
                  alignSelf: "flex-end",
                }}
                variant="outlined"
                onClick={() => {
                  window.open(
                    "https://olimpiadageograficzna.edu.pl"
                  );
                }}
              >
                Przejdź na stronę Olimpiady →
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}
