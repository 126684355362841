import React from "react";

import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Grid } from "@mui/material";

export function MatchMobileQuestion(props) {
  return (
    <Box>
      <Typography><b>{props.text}</b></Typography>
      {props.answers.map((answer) => {
        let style={}
        if (props.correctAnswer && props.correctAnswer[answer.id] === props.selectedMatches[answer.id])
          style.color = "green";
        else if (props.correctAnswer) 
          style.color = "red";
        return (
        <Grid container sx={{mt: 2}}>
            <Grid item xs={6}>
            <Typography style={style} textAlign={'right'} marginRight={4}>{answer.text}</Typography>
            </Grid>
            <Grid item xs={6}>
            <FormControl sx={{width: '50%', alignItems: 'left', alignContent: 'left', display:'flex'}}>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.selectedMatches[answer.id]}
                disabled={props.disabled || props.checkRequested}
                onChange={(e) => props.handleMatchEvent(answer.id, parseInt(e.target.value), props.id)}
            >
                {props.boxes.map((box) =>  
                    <MenuItem value={box.id}>{box.short_text}</MenuItem>
                )}
            </Select>
            </FormControl>
            </Grid>
        </Grid>
      )})}

    </Box>
    
  );
}


