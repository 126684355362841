import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";

export default function FinalStageMap(props) {
  var schoolPosition = [
    props.hostSchoolAddresses[0].latitude,
    props.hostSchoolAddresses[0].longitude,
  ];
  return (
    <MapContainer center={schoolPosition} zoom={13}>
      <ChangeScale scale={props.scale} schoolPosition={schoolPosition} />
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={schoolPosition}>
        <Popup>{props.hostSchools[0].name} - gospodarz</Popup>
      </Marker>

      {props.hostSchoolAddresses.length > 1 &&
            <Marker position={[props.hostSchoolAddresses[1].latitude, props.hostSchoolAddresses[1].longitude]}>
              <Popup>{props.hostSchools[1].name} - gospodarz</Popup>
            </Marker>
      }
    
      {/* <Marker position={props.overnightPlace.position}>
        <Popup>{props.overnightPlace.name} - miejsce noclegu uczestników</Popup>
      </Marker> */}
      {props.otherPoints.map((point) => (
        <Marker
          position={[point.coordinates.latitude, point.coordinates.longitude]}
        >
          <Popup>
            {point.name} - {point.description}
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
}
function ChangeScale(props) {
  const map = useMap();
  if (props.scale === "city") map.setView(props.schoolPosition, 13);
  else map.setView(props.schoolPosition, 5);
  return null;
}
