import { Box, CircularProgress, Typography } from "@mui/material";

export default function Loader() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress size={140} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              height: { lg: 80, xs: 80 },
              width: { lg: 80, xs: 80 },
              borderRadius: "50%",
              left: "50px",
            }}
            component="img"
            alt="logo"
            src="/logo.ico"
          />
        </Box>
      </Box>
    </Box>

    //   <CircularProgress />
  );
}
