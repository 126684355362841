import {
  Box,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import FitScreenIcon from "@mui/icons-material/FitScreen";
import PhotoDialog from "../PhotoDialog";
import { useTheme } from "@emotion/react";
import ArticleIcon from '@mui/icons-material/Article';


export default function Artifacts(props) {
  const [isPhotoOpen, setPhotoOpen] = useState(false);
  const [image, setImage] = useState(null);
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <div>
      <Typography variant="h4">Pamiątki</Typography>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <ImageList
          sx={{
            width: "80%",
            height: {xs: props.artifacts.length === 1 ? 450 : 750, md: 450},
            mt: 3,
            maxWidth: `${800 * props.artifacts.length}px`
          }}
          cols={isMediumScreen ? 1 : props.artifacts.length}
        >
          {props.artifacts.map((item) => (
            <ImageListItem key={item.id}>
              <Box
                sx={{
                  mt: 1,
                  height: "440px",
                  objectFit: "cover",
                  objectPosition: "top",
                  width: "100%",
                }}
                component="img"
                src={item.image}
                srcSet={item.image}
                alt={item.name}
                loading="lazy"
              />
              {item.artifact_type.name === 'Artykuły prasowe' && 
                <ImageListItemBar
                  title={item.name}
                  actionIcon={
                    <IconButton
                      sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                      aria-label={`info about ${item.name}`}
                      onClick={() => {
                        window.open(`${window.location.origin}/${item.pdf_path}`)
                      }}
                    >
                      <ArticleIcon />
                    </IconButton>
                  }
                          />
              }
              {item.artifact_type.name !== 'Artykuły prasowe' &&
              <ImageListItemBar
                title={item.name}
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                    aria-label={`info about ${item.name}`}
                    onClick={() => {
                      setImage(item.image);
                      setPhotoOpen(true);
                    }}
                  >
                    <FitScreenIcon />
                  </IconButton>
                }
              />}
            </ImageListItem>
          ))}
        </ImageList>
        <PhotoDialog
          image={image}
          onClose={() => setPhotoOpen(false)}
          isPhotoOpen={isPhotoOpen}
          isLast={true}
          isFirst={true}
        />
      </Box>
    </div>
  );
}
