import { ToolBar } from "../components/Toolbar";
import FitScreenIcon from "@mui/icons-material/FitScreen";
import ArticleIcon from "@mui/icons-material/Article";
import {
  Box,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import PageHeader from "../components/PageHeader";
import PhotoDialog from "../components/PhotoDialog";
import ArtifactsSelectionBar from "../components/ArtifactsSelectionBar";
import { useSearchParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import axios from "axios";
import { path } from "../consts";
import Loader from "../components/Loader";
import axiosRetry from 'axios-retry';
import Footer from "../components/Footer";

axiosRetry(axios, { retries: 3 });

function ArtifactsList() {
  const [isPhotoOpen, setPhotoOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [artifactsList, setArtifactsList] = useState(null);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedEditions, setSelectedEditions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [artifactsTypes, setArtifactsTypes] = useState(null);
  const [competitionEditionList, setCompetitionEditionList] = useState(null);
  const [searchPhrase, setSearchPhrase] = useState(null);

  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("bmd"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchData = async () => {
      axios
        .get(`${path}/competition/1001/artifacts/get-page-data`)
        .then((res) => {
          setArtifactsList(res.data.artifacts);
          setArtifactsTypes(res.data.artifact_types);
          setCompetitionEditionList(res.data.competitions);
          if (searchParams.get("type"))
            setSelectedTypes([parseInt(searchParams.get("type"))]);
          else setSelectedTypes(res.data.artifact_types.map((type) => type.id));
          setSelectedEditions(
            res.data.competitions.map((edition) => edition.id)
          );
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    };
    fetchData();
  }, [searchParams]);

  useEffect(() => {
    if (competitionEditionList) {
      if (!searchPhrase)
        setSelectedEditions(
          competitionEditionList.map((competition) => competition.id)
        );
      else {
        const delayDebounceFn = setTimeout(() => {
          axios
            .get(`${path}/competition/1001/search/${searchPhrase}`)
            .then((res) => {
              setSelectedEditions(
                res.data.map((competition) => competition.id)
              );
            })
            .catch((err) => {
              console.log(err.message);
            });
        }, 500);
        return () => clearTimeout(delayDebounceFn);
      }
    }
  }, [searchPhrase, competitionEditionList]);

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Box sx={{ position: "sticky", top: 0, zIndex: 99999 }}>
            <ToolBar></ToolBar>
          </Box>
          <PageHeader />
          <Typography
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            variant={isMobile ? "h4" : "h2"}
            sx={{ mt: {bmd: 10, xs: 7}, px: 3 }}
          >
            Pamiątki Olimpiady Geograficznej
          </Typography>
          <ArtifactsSelectionBar
            selectedTypes={selectedTypes}
            selectedEditions={selectedEditions}
            searchPhrase={searchPhrase}
            setSearchPhrase={setSearchPhrase}
            setSelectedEditions={setSelectedEditions}
            setSelectedTypes={setSelectedTypes}
            artifactsTypes={artifactsTypes}
            competitionEditionList={competitionEditionList}
            isSmallScreen={isMobile}
          />
          <Box
            sx={{
              display: " flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 3,
            }}
          >
            <ImageList
              sx={{ width: "95%", mt: 3 }}
              cols={isLargeScreen ? 4 : isSmallScreen ? 1 : 2}
            >
              {artifactsList.map((item) => {
                if (
                  selectedTypes.includes(item.artifact_type.id) &&
                  (!item.competition ||
                    selectedEditions.includes(item.competition.id))
                ) {
                  return (
                    <ImageListItem key={item.id}>
                      <img
                        src={`${item.image}`}
                        srcSet={`${item.image}`}
                        alt={item.name}
                        loading="lazy"
                      />
                      {item.artifact_type.name === 'Artykuły prasowe' && 
                      <ImageListItemBar
                        title={item.name}
                        actionIcon={
                          <IconButton
                            sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                            aria-label={`info about ${item.name}`}
                            onClick={() => {
                              window.open(`${window.location.origin}/${item.pdf_path}`)
                            }}
                          >
                            <ArticleIcon />
                          </IconButton>
                        }
                                />
                      }
                       {item.artifact_type.name !== 'Artykuły prasowe' && 
                       <ImageListItemBar
                        title={item.name}
                        actionIcon={
                          <IconButton
                            sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                            aria-label={`info about ${item.name}`}
                            onClick={() => {
                              setImage(`${item.image}`);
                              setPhotoOpen(true);
                            }}
                          >
                            <FitScreenIcon />
                          </IconButton>
                        }
                      />}
                    </ImageListItem>
                  );
                } else return null;
              })}
            </ImageList>
          </Box>
          <PhotoDialog
            image={image}
            onClose={() => setPhotoOpen(false)}
            isFirst={true}
            isLast={true}
            isPhotoOpen={isPhotoOpen}
          />
          <Footer/>
        </div>
      )}
    </div>
  );
}

export default ArtifactsList;
