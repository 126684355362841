import { Typography, Button, Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { MatchQuestion } from "../Questions/Match";
import ChoiceQuestion from "../Questions/Choice";
import OpenQuestion from "../Questions/Openj";
import { useEffect, useState } from "react";
import axios from "axios";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { path } from "../../consts";
import axiosRetry from 'axios-retry';
import ChoiceSingleLongQuestion from "../Questions/ChoiceSingleLong";
import ChoiceMultipleLongQuestion from "../Questions/ChoiceMultiLong";
import { MatchMobileQuestion } from "../Questions/MatchMobile";

axiosRetry(axios, { retries: 3 });

export default function Questions(props) {
  const [answerSheet, setAnswerSheet] = useState(null);
  const [correctAnswers, setCorrectAnswers] = useState(null);
  const [result, setResult] = useState(null);
  const [checkRequested, setCheckRequested] = useState(false)
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("bmd"));
  console.log(answerSheet)
  console.log(props.questions)

  useEffect(() => {
    setAnswerSheet(null);
    setCorrectAnswers(null);
    setResult(null);
    var newAnswerSheet = {};
    props.questions.forEach((element) => {
      element.questions.forEach((question) => {
        if (
          question.question_type === "single" ||
          question.question_type === "multiple"
        )
          newAnswerSheet[question.id] = { id: question.id, answers: [] };
        else if (question.question_type === "open")
          newAnswerSheet[question.id] = { id: question.id, answers: "" };
        else if (
          question.question_type === "match-single" ||
          question.question_type === "match-multiple"
        ) {
          newAnswerSheet[question.id] = { id: question.id, answers: {} };
          question.answers.forEach(
            (answer) =>
              (newAnswerSheet[question.id]["answers"][answer.id] = null)
          );
        }
      });
    });
    setAnswerSheet(newAnswerSheet);
    shuffleArray(props.questions);

    // return () => {
    //   setCorrectAnswers(null)
    // };
  }, [props.questions]);

  function resetAnswers() {
    var newAnswerSheet = {};
    props.questions.forEach((element) => {
      element.questions.forEach((question) => {
        if (
          question.question_type === "single" ||
          question.question_type === "multiple"
        )
          newAnswerSheet[question.id] = { id: question.id, answers: [] };
        else if (question.question_type === "open")
          newAnswerSheet[question.id] = { id: question.id, answers: "" };
        else if (
          question.question_type === "match-single" ||
          question.question_type === "match-multiple"
        ) {
          newAnswerSheet[question.id] = { id: question.id, answers: {} };
          question.answers.forEach(
            (answer) =>
              (newAnswerSheet[question.id]["answers"][answer.id] = null)
          );
        }
      });
    });
    setAnswerSheet(newAnswerSheet);
    setCorrectAnswers(null);
    setResult(false);
  }

  function selectAnswerSingleChoice(questionId, answerId) {
    setAnswerSheet((prevAnswerSheet) => {
      prevAnswerSheet[questionId].answers = [answerId];
      return JSON.parse(JSON.stringify(prevAnswerSheet));
    });
  }

  function selectAnswerMultipleChoice(questionId, answerId) {
    setAnswerSheet((prevAnswerSheet) => {
      let newAnswerSheet = JSON.parse(JSON.stringify(prevAnswerSheet));
      const index = newAnswerSheet[questionId].answers.indexOf(answerId);
      if (index > -1) newAnswerSheet[questionId].answers.splice(index, 1);
      else newAnswerSheet[questionId].answers.push(answerId);
      return newAnswerSheet;
    });
  }

  function handleDragEventMultiple(event, questionId) {
    setAnswerSheet((prevAnswerSheet) => {
      let newAnswerSheet = JSON.parse(JSON.stringify(prevAnswerSheet));
      const { over } = event;
      newAnswerSheet[questionId].answers[event.active.id] = over
        ? over.id
        : null;
      return newAnswerSheet;
    });
  }

  function handleMatchEventMultiple(answerId, boxId, questionId) {
    setAnswerSheet((prevAnswerSheet) => {
      let newAnswerSheet = JSON.parse(JSON.stringify(prevAnswerSheet));
      newAnswerSheet[questionId].answers[answerId] = boxId
      return newAnswerSheet;
    });
  }
  

  function handleDragEventSingle(event, questionId) {
    setAnswerSheet((prevAnswerSheet) => {
      let newAnswerSheet = JSON.parse(JSON.stringify(prevAnswerSheet));
      const { over } = event;
      for (const [key, value] of Object.entries(
        newAnswerSheet[questionId].answers
      )) {
        if (over && value === over.id) newAnswerSheet[questionId].answers[key] = null;
      }
      newAnswerSheet[questionId].answers[event.active.id] = over
        ? over.id
        : null;
      return newAnswerSheet;
    });
  }

  function handleMatchEventSingle(answerId, boxId, questionId) {
    setAnswerSheet((prevAnswerSheet) => {
      let newAnswerSheet = JSON.parse(JSON.stringify(prevAnswerSheet));
      for (const [key, value] of Object.entries(
        newAnswerSheet[questionId].answers
      )) {
        if (boxId && value === boxId) newAnswerSheet[questionId].answers[key] = null;
      }
      newAnswerSheet[questionId].answers[answerId] = boxId
      return newAnswerSheet;
    });
  }


  function handleAnswerChange(newAnswer, questionId) {
    setAnswerSheet((prevAnswerSheet) => {
      let newAnswerSheet = JSON.parse(JSON.stringify(prevAnswerSheet));
      newAnswerSheet[questionId]["answers"] = newAnswer;
      return newAnswerSheet;
    });
  }

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  function areAnswersLong(question) {
    let isLong = false
    question.answers.forEach(answer => {
      if (answer.text.length > 15) {isLong=true; return;}
    });
    return isLong
  }

  function areBoxTextsLong(question) {
    let isLong = false
    question.question_boxes.forEach(answer => {
      if (answer.short_text.length > 50) {isLong=true; return;}
    });
    return isLong
  }

  async function checkAnswers(e) {
    setCheckRequested(true)
    axios({
      url: `${path}/check_answers`,
      method: "post",
      data: Object.values(answerSheet),
    })
      .then((res) => {
        setCorrectAnswers(res.data.correct_answers);
        setResult(res.data.results);
        setCheckRequested(false);
        const anchor = (e.target.ownerDocument || document
          ).querySelector('#result-check-anchor');
          if (anchor) {
            anchor.scrollIntoView({
              block: 'center',
            });
          }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  return (
    <div>
      { !answerSheet ? (
        <Typography>Loading ...</Typography>
      ) : (
        <Box sx={{mx: {md: 30, sx: 5}}} >
          <Typography variant="h4" sx={{ mb: 2 }} id='result-check-anchor'>
            Sprawdź się na zadaniach z {props.editionNumber} edycji!
          </Typography>
          {result && (
            <Typography sx={{ mt: 4, mb: 4, fontSize: 20 }}>
              <b>Twój wynik to {Math.round(result.points * 100)/100}/{Math.round(result.points_max * 100)/100} punktów!</b>
            </Typography>
          )}
          {props.questions.map((group) => (
            <>
              {group.symbol} 
              <Typography>{group.text}</Typography>
              {group.multimedia && (
                <Box
                  sx={{
                    mt: 1,
                    width: "70%",
                    borderRadius: "10px",
                    justifyContent: "left",
                    alignItems: "left",
                  }}
                  component="img"
                  src={group.multimedia}
                />
              )}
              {group.questions
                .sort((a, b) => a.order - b.order)
                .map((question) => {
                  let hasLongAnswers = areAnswersLong(question)
                  return (
                  <Box sx={{ mt: 4, mb: 4 }}>
                    {(question.question_type === "single" ||
                      question.question_type === "multiple") && (!hasLongAnswers) 
                      && (
                      <ChoiceQuestion
                        questionText={question.text}
                        disabled={result ? true : false}
                        id={question.id}
                        answers={question.answers}
                        selectedAnswers={answerSheet[question.id].answers}
                        selectAnswer={
                          question.question_type === "single"
                            ? selectAnswerSingleChoice
                            : selectAnswerMultipleChoice
                        }
                        correctAnswer={
                          correctAnswers === null
                            ? null
                            : correctAnswers[question.id].answers
                        }
                        checkRequested={checkRequested}
                      />
                    )}
                    {(question.question_type === "single") && (hasLongAnswers) 
                      && (
                      <ChoiceSingleLongQuestion
                        questionText={question.text}
                        disabled={result ? true : false}
                        id={question.id}
                        answers={question.answers}
                        selectedAnswers={answerSheet[question.id].answers}
                        selectAnswer={selectAnswerSingleChoice}
                        correctAnswer={
                          correctAnswers === null
                            ? null
                            : correctAnswers[question.id].answers
                        }
                        checkRequested={checkRequested}
                      />
                    )}
                    {(question.question_type === "multiple") && (hasLongAnswers) 
                      && (
                      <ChoiceMultipleLongQuestion
                        questionText={question.text}
                        disabled={result ? true : false}
                        id={question.id}
                        answers={question.answers}
                        selectedAnswers={answerSheet[question.id].answers}
                        selectAnswer={selectAnswerMultipleChoice}
                        correctAnswer={
                          correctAnswers === null
                            ? null
                            : correctAnswers[question.id].answers
                        }
                        checkRequested={checkRequested}
                      />
                    )}
                    {((question.question_type === "match-single" ||
                      question.question_type === "match-multiple") &&
                      (isMobile || hasLongAnswers || areBoxTextsLong(question)) ) && 
                      (
                      <MatchMobileQuestion
                        id={question.id}
                        boxes={question.question_boxes}
                        text={question.text}
                        disabled={result ? true : false}
                        selectedMatches={answerSheet[question.id].answers}
                        answers={question.answers}
                        handleMatchEvent={
                          question.question_type === "match-single"
                            ? handleMatchEventSingle
                            : handleMatchEventMultiple
                        }
                        correctAnswer={
                          correctAnswers === null
                            ? null
                            : correctAnswers[question.id].answers
                        }
                        checkRequested={checkRequested}
                      ></MatchMobileQuestion>
                    )}
                    {((question.question_type === "match-single" ||
                      question.question_type === "match-multiple") &&
                      !(isMobile || hasLongAnswers || areBoxTextsLong(question)) )  && 
                      (
                      <MatchQuestion
                        id={question.id}
                        boxes={question.question_boxes}
                        text={question.text}
                        disabled={result ? true : false}
                        selectedMatches={answerSheet[question.id].answers}
                        type = {question.question_type}
                        answers={question.answers}
                        handleDragEvent={
                          question.question_type === "match-single"
                            ? handleDragEventSingle
                            : handleDragEventMultiple
                        }
                        correctAnswer={
                          correctAnswers === null
                            ? null
                            : correctAnswers[question.id].answers
                        }
                        checkRequested={checkRequested}
                      ></MatchQuestion>
                    )}
                    {question.question_type === "open" && (
                      <OpenQuestion
                        id={question.id}
                        text={question.text}
                        preAnswer={question.pre_answer}
                        postAnswer={question.post_answer}
                        disabled={result ? true : false}
                        selectedAnswer={answerSheet[question.id].answers}
                        handleInputChange={handleAnswerChange}
                        correctAnswer={
                          correctAnswers === null
                            ? null
                            : correctAnswers[question.id].answers
                        }
                        checkRequested={checkRequested}
                      ></OpenQuestion>
                    )}    
                    <Box sx={{mt: 3}}></Box>                
                    {correctAnswers &&
                      correctAnswers[question.id].points ===
                        correctAnswers[question.id].max_points && (
                        <>
                          <DoneOutlineIcon style={{ color: "green" }} />
                          <Typography style={{ color: "green" }}>
                            {"("}
                            {Math.round(correctAnswers[question.id].points * 100) / 100}/
                            {Math.round(correctAnswers[question.id].max_points * 100)/ 100}
                            {")"}
                          </Typography>
                        </>
                      )}
                    {correctAnswers &&
                      correctAnswers[question.id].points <
                        correctAnswers[question.id].max_points &&
                      correctAnswers[question.id].points > 0 && (
                        <>
                          <PendingOutlinedIcon style={{ color: "#ff9900" }} />
                          <Typography style={{ color: "#ff9900" }}>
                            {"("}
                            {Math.round(correctAnswers[question.id].points * 100)/100}/
                            {Math.round(correctAnswers[question.id].max_points*100)/100}
                            {")"}
                          </Typography>
                        </>
                      )}
                    {correctAnswers &&
                      correctAnswers[question.id].points === 0 && (
                        <>
                          <CancelOutlinedIcon style={{ color: "red" }} />
                          <Typography style={{ color: "red" }}>
                            {"("}
                            {Math.round(correctAnswers[question.id].points *100)/100}/
                            {Math.round(correctAnswers[question.id].max_points*100)/100}
                            {")"}
                          </Typography>
                        </>
                      )}

                  </Box>)
                })}
            </>
          ))}
          <Button variant="outlined" sx={{ mt: 5 }} onClick={resetAnswers} disabled={checkRequested}>
            Resetuj
          </Button>
          <Button
            variant="contained"
            sx={{ mt: 5, ml: 3 }}
            onClick={checkAnswers}
            disabled={(result ? true : false) || checkRequested}
          >
            Sprawdź
          </Button>
          <Typography sx={{ mt: 3 }}>
            Inne zadania ze wszystkich edycji Olimpiady do pobrania{" "}
            <a href="http://www.olimpiadageograficzna.edu.pl/zadania/">
              tutaj
            </a>
            .
          </Typography>
        </Box>
      )}
    </div>
  );
}
