import { Box, Typography, Grid } from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function OlympiadCard(props) {
  const navigate = useNavigate();
  const [wasReleased, setWasReleased] = useState(false);
  

  return (
    <Box
      sx={{
        mx: 3,
        p: 3,
        my: 2,
        boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.15)",
        "@keyframes color-change": {
          "0%": {
            backgroundColor: "#ffffff",
            boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.15)",
            mx: 3,
          },
          "100%": {
            backgroundColor: "#c9d6f0",
            boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.4)",
            mx: 1,
          },
        },
        "@keyframes color-change-reverse": {
          "0%": {
            backgroundColor: "#c9d6f0",
            boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.4)",
            mx: 1,
          },
          "100%": {
            backgroundColor: "#ffffff",
            boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.15)",
            mx: 3,
          },
        },
        "&:hover": {
          backgroundColor: {bmd: "#c9d6f0", xs: '#ffffff'},
          boxShadow: {bmd: "0px 0px 30px rgba(0, 0, 0, 0.4)", xs: "0px 0px 30px rgba(0, 0, 0, 0.15)"},
          mx: {bmd: 1, xs: 3},
          animation: {bmd: "color-change 0.6s ease", xs: null}
        },
        borderRadius: "20px",
        animation: {bmd: wasReleased ? "color-change-reverse 0.6s ease" : null, xs: null}
      }}
      onMouseLeave ={()=> setWasReleased(true)}
      

      onClick={() => {
        navigate(`/olimpiada/${props.id}`);
        window.scrollTo(0, 0);
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography>
            <b>{props.editionNumber} Olimpiada Geograficzna</b>
          </Typography>
          <Box
            sx={{
              mt: 1,
              width: "90%",
              height: "220px",
              objectFit: "cover",
              objectPosition: "top",
            }}
            component="img"
            alt= {`${props.editionNumber} olympiad image`}
            src={props.photo}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Box fontSize={17} display='flex' flexDirection='row'>
            <Box display='flex' flexDirection='row' alignItems='flex-start' marginLeft={3}>
              <ApartmentIcon sx={{ position: "relative", top: 2, mr: 1 }} />
              <Typography>{props.hostCity}</Typography>
            </Box>
            <Box marginLeft='auto' display='flex' flexDirection='row' alignItem='right' marginRight={3}>
              <CalendarMonthIcon
                sx={{position: "relative", top: 2, mr: 1 }}
              />
              <Typography>
              {props.schoolYear}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
