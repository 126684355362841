import { Box, Button, Grid, Typography } from "@mui/material";
import OlympiadCard from "./OlympiadCard";
import { toRomanNumber } from "../consts";
import { useNavigate } from "react-router";

export default function SelectionOfCompetitions(props) {
  const navigate = useNavigate();


  return (
    <Box>
      <Typography display="flex" variant="h4" sx={{ mt: 5, ml: 3 }}>
        Poznaj inne edycje:
      </Typography>
      <Grid
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{mt:5, px: {xs:2, mdd: 5, lg: 10}}}
      >
        {props.selectedCompetitions.map((competition) => (
          <Grid item xs={12} md={6} bmd={4} key={competition.id}>
            <OlympiadCard
              photo={competition.main_image}
              editionNumber={toRomanNumber[competition.edition_number]}
              hostCity={competition.host_cities[0]}
              schoolYear={competition.school_year}
              id={competition.id}
            />
          </Grid>
        ))}
      </Grid>
      <Button
        onClick={() => {
          navigate(`/olimpiada/`);
          window.scrollTo(0, 0);
        }}
      >
        Więcej edycji...
      </Button>
    </Box>
  );
}
