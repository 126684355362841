import { useTheme } from "@emotion/react";
import { Box, Grid, IconButton, Typography, useMediaQuery } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from "react-router-dom";
import { toRomanNumber } from "../../consts";


export default function OlympiadHeader(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("bmd"));
  const navigate = useNavigate();

  return (
    <div>
      <Grid container sx={{width: '100%'}}>
        <Grid item xs={3} sx={{display: 'flex', justifyContent:'flex-end'}}>
          {props.editionNumber > 1 && (
          <Box sx={{mt: 9}} >
          <Typography sx={{display: {xs: "none", md: 'flex'}}}>
              {toRomanNumber[props.editionNumber-1]} OG
          </Typography>
          <IconButton 
            onClick={() => {
            navigate(`/olimpiada/${props.editionNumber-1}`);
            window.scrollTo(0, 0);
          }}
             >
            <KeyboardArrowLeftIcon color='primary'/>
          </IconButton>
          </Box>)}
        </Grid>
        <Grid item xs={6}>
          <Typography
            textAlign="center"
            variant={isMobile ? "h4" : "h2"}
            sx={{ mt: {bmd: 10, xs: 7} }}
          >
            {props.editionNumberRoman} Olimpiada Geograficzna
          </Typography>
          <Typography
            display="flex"
            justifyContent="center"
            alignItems="center"
            variant="h5"
          >
            Rok szkolny {props.schoolYear}
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{display: 'flex', justifyContent:'flex-start  '}}>
          {/* temporary solution, should check for last edition */}
          {props.editionNumber < 50 &&
          <Box sx={{mt: 9}} >
          <Typography sx={{display: {xs: "none", md: 'flex'}}}>
              {toRomanNumber[props.editionNumber+1]} OG
          </Typography>
          <IconButton 
            onClick={() => {
              navigate(`/olimpiada/${props.editionNumber+1}`);
              window.scrollTo(0, 0);
            }}>
            <KeyboardArrowRightIcon color='primary'/>
          </IconButton>
          </Box>}
        </Grid>

      </Grid>
    </div>
  );
}
