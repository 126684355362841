import VizSensor from "react-visibility-sensor";
import { useState } from "react";
import { Box, Grow } from "@mui/material";

export default function Animation2(props) {
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  return (
    <VizSensor
      onChange={(isVisible) => {
        setIsComponentVisible(isVisible);
      }}
      partialVisibility={true}
    >
      <Grow in={isComponentVisible} timeout={1000}>
        <div>{props.component}</div>
      </Grow>
    </VizSensor>
  );
}
