import React from "react";
import { DndContext } from "@dnd-kit/core";
import { Droppable } from "../Droppable";
import { Draggable } from "../Draggable";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";

export function MatchQuestion(props) {
  return (
    <div>
      <Typography><b>{props.text}</b></Typography>
      <DndContext onDragEnd={(e) => props.handleDragEvent(e, props.id)}>
        {Object.values(props.answers).map((answer) => {
          if (props.selectedMatches[answer.id] === null)
            return <Draggable disabled={props.disabled || props.checkRequested} id={answer.id}>{answer.text}</Draggable>;
          else return null;
        })}

        <Grid container sx={{ pl: 10, pr: 10, pt: 3, alignItems: 'center', justifyContent: 'center' }}>
          {props.boxes.map((box) => (
            <Grid item xl={3} lg={4} md={6}>
              <Droppable text={box.short_text} key={box.id} id={box.id} size={props.type === "match-single" ? 200 : 400}>
                {props.answers.map((answer) => {
                  let style = {};
                  if (
                    props.correctAnswer &&
                    props.correctAnswer[answer.id] === box.id
                  )
                    style.backgroundColor = "green";
                  else if (props.correctAnswer) style.backgroundColor = "red";

                  if (props.selectedMatches[answer.id] === box.id)
                    return (
                      <Draggable style={style} id={answer.id} disabled={props.disabled || props.checkRequested}>
                        {answer.text}
                      </Draggable>
                    );
                  else return null;
                })}
              </Droppable>
            </Grid>
          ))}
        </Grid>
      </DndContext>
    </div>
  );
}
