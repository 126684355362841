import { Box, Button, Grid, Typography } from "@mui/material";

export default function Footer(){
  return (
    <Box sx={{mx: 10, mt: 10, mb:3}}>
        <Typography textAlign='center' fontSize={13}>
        Wirtualne Muzeum Olimpiady Geograficznej jest realizowane przez Polskie Towarzystwo Geograficzne w ramach Projektu „Geografia - pasja od dziecka, inspiracja na całe życie”. <br/>
        Projekt dofinansowany ze środków budżetu państwa, przyznanych przez Ministra Edukacji i Nauki w ramach Programu Społeczna Odpowiedzialność Nauki II.
        </Typography>
        <Box sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'row'}, alignItems: 'center', justifyContent: 'center'}}>
            <Box component='img' src='mein.png' alt='mein' width='200px' height='100%' />
            <Box component='img' src='son.png' alt='son'  width='200px'/>
        </Box>
        <Typography textAlign='center' fontSize={12} sx={{mt: 2}}>
          Masz pamiątki lub inne materiały których brakuje na stronie? Chciałbyś podzielić się swoją historią związaną z Olimpiadą? <br/>
          Skontakuj się z nami: <a href='mailto:jubileusz@olimpiadageograficzna.edu.pl'>jubileusz@olimpiadageograficzna.edu.pl</a>
        </Typography>
        <Typography textAlign='center' fontSize={10} sx={{mt: 2}} onClick={() => window.open("https://olimpiadageograficzna.edu.pl")}>
        © Polskie Towarzystwo Geograficzne - 2023 <br/>
        Olimpiada Geograficzna
        </Typography>
    </Box>
  );
}

