import { Box, Button, Typography } from "@mui/material";
import { ToolBar } from "../components/Toolbar";
import { useNavigate } from "react-router";
import Footer from "../components/Footer";

export default function Error404(){
  const navigate = useNavigate();

  return (
    <div>
        <Box sx={{ position: "sticky", top: 0, zIndex: 99999 }}>
            <ToolBar></ToolBar>
        </Box>
        <Box sx={{justifyContent: 'center', alignItems: 'center', display:'flex', flexDirection: 'column'}}>
        <Button
          sx={{
            "&:hover": {
                backgroundColor: null,
                color: null,
                boxShadow: null,
              },
          }}
          onClick={() => navigate("/")}>
            <Box
                sx={{
                height: { lg: 150, xs: 110 },
                width: { lg: 150, xs: 110 },
                mt:10, mr:10, ml: 10,
                borderRadius: "50%",
                }}
                component="img"
                alt="logo"
                src="/logo.ico"
                
            />
        </Button>
        <Typography sx={{mt:5, mr:10, ml: 10}} variant='h2'>
            ERROR 404
        </Typography>
        <Typography sx={{m:3, textAlign:'center'}} variant='h4'>
            Strona o podanym adresie nie została znaleziona :(<br/>
            Skorzystaj z menu nawigacyjnego dostępnego u góry
        </Typography>
        </Box>
        <Footer/>
    </div>
  );
}

