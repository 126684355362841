import { Typography, Box, useMediaQuery } from "@mui/material";
import "swiper/css";
import { SwiperSlide, Swiper } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css/navigation";
import { useState } from "react";
import PhotoDialog from "../PhotoDialog";
import { useTheme } from "@emotion/react";
import YouTube from 'react-youtube';

export default function ImageGallery(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("bmd"));
  let slidesPerView;
  if (isMobile) slidesPerView = 1;
  else if (props.images.length < 3) slidesPerView = props.images.length;
  else slidesPerView = 3;
  const [isPhotoOpen, setPhotoOpen] = useState(false);
  const [imageIdx, setImageIdx] = useState(null);
  const [swiper, setSwiper] = useState(null);

  const slideTo = (index) => swiper.slideTo(index);


  return (
    <div>
      {props.images.length > 0 && (
        <div>
          <Typography variant="h4">Galeria z zawodów</Typography>
          <Swiper
            onSwiper={setSwiper}
            modules={[Navigation]}
            spaceBetween={50}
            slidesPerView={slidesPerView}
            navigation
          >
            {props.images.map((image, index) => (
              <SwiperSlide key={index}>
                {
                  (image.path[0] === '.') ?                 
                    <Box
                    sx={{
                      mt: 1,
                      maxWidth: "90%",
                      maxHeight: "330px"
                    }}
                    component="img"
                    src={image.path}
                    onClick={() => {
                      setImageIdx(index)
                      setPhotoOpen(true);
                    }}
                  /> :
                   <Box                     
                   sx={{
                      mt: 1,
                      maxWidth: "90%",
                      maxHeight: "330px"
                    }} >
                     <YouTube opts={{width: '100%', height: "300px"}} videoId="_fJMPvwEZNE"/>
                    </Box>
                }

              </SwiperSlide>
            ))}
          </Swiper>
          <PhotoDialog
            image={imageIdx !== null ? props.images[imageIdx].path : null}
            description={imageIdx !== null ? props.images[imageIdx].description : null}
            isLast={imageIdx === props.images.length-1}
            isFirst={imageIdx === 0}
            handleNextClick = {() => setImageIdx((prevImageIdx) => (prevImageIdx < props.images.length-1 ? prevImageIdx+1 : prevImageIdx))}
            handlePrevClick = {() => setImageIdx((prevImageIdx) => (prevImageIdx > 0 ?  prevImageIdx-1 : prevImageIdx))}
            onClose={() => {setPhotoOpen(false); slideTo(isMobile ? imageIdx : Math.max(imageIdx-1, 0))}}
            isPhotoOpen={isPhotoOpen}
          />
        </div>
      )}
    </div>
  );
}
