import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { useState } from "react";

export default function SelectionOfArtifacts(props) {
  const navigate = useNavigate();
  const [wasReleased, setWasReleased] = useState(false);

  return (
    <Box>
      <Typography display="flex" variant="h4" sx={{ mt: 5, ml: 3 }}>
        Odkryj nasze pamiątki:
      </Typography>
      <Grid container spacing={5} sx={{ p: 3 }}>
        {props.selectedArtifacts.map((artifact) => (
          <Grid item xs={12} md={Math.max(4, (12/props.selectedArtifacts.length))} key={artifact.id}>
            <Box
              sx={{
                mx: 3,
                p: 3,
                my: 2,
                boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.15)",
                "@keyframes color-change": {
                  "0%": {
                    backgroundColor: "#ffffff",
                    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.15)",
                    mx: 3,
                  },
                  "100%": {
                    backgroundColor: "#c9d6f0",
                    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.4)",
                    mx: 1,
                  },
                },
                "@keyframes color-change-reverse": {
                  "0%": {
                    backgroundColor: "#c9d6f0",
                    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.4)",
                    mx: 1,
                  },
                  "100%": {
                    backgroundColor: "#ffffff",
                    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.15)",
                    mx: 3,
                  },
                },
                "&:hover": {
                  backgroundColor: {md: "#c9d6f0", xs: '#ffffff'},
                  boxShadow: {md: "0px 0px 30px rgba(0, 0, 0, 0.4)", xs: "0px 0px 30px rgba(0, 0, 0, 0.15)"},
                  mx: {md: 1, xs: 3},
                  animation: {md: "color-change 0.6s ease", xs: null}
                },
                borderRadius: "20px",
                animation: {md: wasReleased ? "color-change-reverse 0.6s ease" : null, xs: null}
              }}
              onMouseLeave ={()=> setWasReleased(true)}
              onClick={() => {
                navigate(`/pamiatki/?type=${artifact.artifact_type.id}`);
                window.scrollTo(0, 0);
              }}
            >
              <Typography>
                <b>{artifact.artifact_type.name}</b>
              </Typography>
              <Box
                sx={{
                  mt: 1,
                  width: "90%",
                  borderRadius: "10px",
                  maxHeight: "300px",
                  objectFit: "cover",
                  objectPosition: "top",
                }}
                component="img"
                src={artifact.image}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
      <Button
        onClick={() => {
          navigate(`/pamiatki/`);
          window.scrollTo(0, 0);
        }}
      >
        Więcej pamiątek...
      </Button>
    </Box>
  );
}
