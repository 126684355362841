import { Box, Typography, Grid } from "@mui/material";

export default function PersonDescription(props) {
  const size = props.photo === null ? 12 : 9.5;
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        ml: 5,
        mr: 5,
        mb: 8,
        mt: 5,
        py: 5,
        boxShadow: 1,
        backgroundColor: "#F5F5DC",
        borderRadius: 1,
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          bmd={2.5}
          display={!props.opposite || props.isSmallScreen ? "flex" : "none"}
          justifyContent="center"
          alignItems="center"
        >
          {props.photo && (
            <Box
              sx={{ borderRadius: 3, mb: 2, maxWidth: 200, maxHeight: 280}}
              component="img"
              alt=""
              src={props.photo}
            />
          )}
        </Grid>
        <Grid item xs={12} bmd={size}>
          <Typography
            variant="h4"
            sx={{ ml: { xs: 3, sm: 6, lg:10 } }}
          >
            {props.person}
          </Typography>
          <Typography
            variant="h6"
            sx={{ ml: { xs: 3, sm: 6, lg:10 }}}
          >
            {props.shortDescription}
          </Typography>
          {props.biogram.map((paragraph) => (
            <>
              <Typography
                sx={{
                  mt: 2,
                  ml: { xs: 3, sm: 6, lg:10 },
                  mr: { xs: 3, sm: 6, lg:10 },
                  textAlign: "justify",
                }}
              >
                {paragraph}
              </Typography>
            </>
          ))}
        </Grid>
        <Grid
          item
          bmd={2.5}
          display={props.opposite && !props.isSmallScreen ? "flex" : "none"}
          justifyContent="center"
          alignItems="center"
        >
          {props.photo && (
            <Box
              sx={{ borderRadius: 3, mb: 2, maxWidth: 200, maxHeight: 280 }}
              component="img"
              alt=""
              src={props.photo}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
