import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { ToolBar } from "../components/Toolbar";
import PageHeader from "../components/PageHeader";
import { useState } from "react";
import PersonDescription from "../components/PersonDescription";
import Animation from "../components/Animation";
import { useTheme } from "@emotion/react";
import Footer from "../components/Footer";

export default function People(props) {
  const importantPeopleData = [
    {
      id: 1,
      title: "",
      name: "Anna",
      surname: "Dylikowa",
      shortDescription: "1912-2000",
      biogram: [
        `Geograf i geomorfolog, specjalistka w zakresie geografii fizycznej, regionalnej
        i geoekologii, autorka podręczników geografii
        i innych książek, pedagog, wychowawca
        młodzieży. W czasie wojny nauczycielka
        tajnego nauczania w Warszawie, wychowawczyni w Domu Ofiar Wojny PCK, po wojnie
        pracownik naukowy Uniwersytetu Łódzkiego.
        Przewodnicząca Zespołu Programowego
        Geografii MOiW, członek podobnego zespołu w MEN. Czynnie uczestniczyła
        także w zagranicznym życiu naukowym oraz w międzynarodowych działaniach na rzecz dydaktyki geografii, m.in. dotyczących aktualizacji treści
        podręczników geografii i historii. Była członkiem honorowym Belgijskiego
        Towarzystwa Geograficznego. Harcmistrzyni, przed wojną komendantka Łódzkiej Chorągwi Harcerek,
        w czasie wojny drużynowa Szarych Szeregów, po wojnie zastępczyni komendanta Chorągwi Łódź Miasto.`,
        ` Założycielka, a właściwie twórca Olimpiady Geograficznej i wieloletnia
        (1974 - 2000) Przewodnicząca jej Komitetu Głównego. Do realizacji stworzonej
        przez siebie idei olimpijskiej - działań znacznie wychodzących poza samą
        organizację zawodów - zgromadziła wokół siebie liczne grono osób rozumiejących
        i akceptujących tę ideę. Ponadto, a może przede wszystkim, postawą swą
        powodowała, że przy wszelkich kontaktach, oprócz kompetencji dominowała
        życzliwość, zarówno w gronie osób współpracujących, jak i w stosunku do
        młodzieży - zawodników oraz nauczycieli.`,
      ],
      photo: "./photos/important_people/dylikowa.jpg",
    },
    {
      id: 2,
      title: " ",
      name: "Jan",
      surname: "Flis",
      shortDescription: "1912-1993",
      biogram: [
        ` Geograf i pedagog, wybitny specjalista
      w zakresie geografii fizycznej i dydaktyki
      geografii, autor wielu książek i podręczników
      głównie z tego zakresu, nauczyciel szkolny
      oraz pracownik naukowy Uniwersytetów
      Lwowskiego, Jagiellońskiego i Warszawskiego,
      a przede wszystkim, najdłużej, Wyższej Szkoły
      Pedagogicznej w Krakowie (1960 - 1982).
      Obrońca Lwowa w 1939 roku, a następnie
      żołnierz Samodzielnej Brygady Strzelców Karpackich i II Korpusu WP.
      Uczestniczył w kampanii libijskiej (m.in. Tobruk) i włoskiej (Monte Cassino,
      Ancona, Rimini)`,
        ` Pasjonat Olimpiady Geograficznej, autor ogromnej liczby zadań na
        zawody, które miały znaczący wpływ na ukierunkowanie nauczania geografii
        w ogólnokształcącej szkole średniej. Dla osób związanych z Olimpiadą był
        Nauczycielem i Przyjacielem, krytycznym lecz życzliwym, zawsze uśmiechniętym i dowcipnym.`,
      ],
      photo: "./photos/important_people/flis.jpg",
    },
    {
      id: 3,
      title: "",
      name: "Józef",
      surname: "Barbag",
      shortDescription: "1903-1982",
      biogram: [
        `Geograf fizyczny, nauczyciel akademicki i urzędnik państwowy związany z Uniwersytetem
      Warszawskim. Prekursor geografii politycznej w Polsce. Wieloletni członek Komisji Metodologii i
      Nauczania Geografii w Międzynarodowej Unii Geograficznej. Przewodniczący podkomisji UNESCO ds.
      Szkolnych Podręczników Geografii PRL—RFN w latach 70—tych.`,
        `W czasie wojny aresztowany i wywieziony do obozu pracy Teplice w Rudawach. Przed wojną pełnił
        obowiązki nauczyciela, w latach 1945—1953 pracował w Ministerstwie Oświaty. Przez wiele lat był
        redaktorem naczelnym czasopisma „Geografia w Szkole”, autor wielu publikacji z
        zakresu metodyki nauczania geografii oraz geografii politycznej. Wiceprzewodniczący KG OG w latach
        1974—1982, brał udział w opracowaniu koncepcji i formy
        działania Olimpiady Geograficznej. Wieloletni członek Komitetu Głównego Olimpiady Geograficznej.`,
      ],

      photo:  "./photos/important_people/barbag.jpg",
    },
    {
      id: 4,
      title: "",
      name: "Zdzisław",
      surname: "Batorowicz",
      shortDescription: "1912-1993",
      biogram: [
        `Geograf, etnograf i etnolog, a także pedagog. Zajmował się badaniami geograficznymi
         i etnograficznymi osadnictwa na Pomorzu, a także dydaktyką geografii. Przez całe swoje
          życie naukowe związany z Łodzią - z Wyższą Szkołą Pedagogiczną i Uniwersytetem Łódzkim. W działalności naukowej zaznaczyły się dwa główne nurty zainteresowań
         - badania geograficzne i etnograficzne osadnictwa na Pomorzu oraz dydaktyka geografii.`,
         `Jako przewodniczący Komisji Geografii Szkolnej brał udział w pracach Zarządu Głównego Polskiego Towarzystwa Geograficznego
          oraz był redaktorem czasopisma "Geografia w Szkole" (1982-1993). Wielokrotnie wyróżniony nagrodami rektora Uniwersytetu Łódzkiego,
           a także Złota Odznaką Polskiego Towarzystwa Geograficznego (1968), a następnie Krzyżem Kawalerskim Orderu Odrodzenia Polaki (1975).
         `,
         `Współtworzył Olimpiadę Geograficzną działając w Komitecie Głównym od jego powołania do roku 1992.`
      ],

      photo: "./photos/important_people/batorowicz.png",
    },
    {
      id: 5,
      title: "",
      name: "Irena",
      surname: "Berne",
      shortDescription: "1915-2013",
      biogram: [
        `Dydaktyk geografii związana ze Studium Nauczycielskim, a następnie OOM m. st. Warszawy.
        Prowadziła ogólnopolskie kursy dla nauczycieli geografii. Nauczycielka geografii i historii,
        krajoznawca, przewodnik turystyki górskiej, autorka licznych publikacji z zakresu nauczania geografii.`,
        `Członek Komitetu Głównego Olimpiady Geograficznej od początku jego istnienia, współorganizatorka
        i autorka koncepcji Olimpiady. Przez wiele lat członek Komisji Zadań oraz Komisji Weryfikacyjnej.
        Wyróżniona Odznaką im. Pani Prof. Dylikowej (Legitymacja nr 1). Laureatka między innymi Krzyża Kawalerskiego Orderu
        Odrodzenia Polski i Medalu Komisji Edukacji Narodowej.`,
      ],

      photo:  "./photos/important_people/berne.jpg",
    },
    {
      id: 6,
      title: "",
      name: "Maria",
      surname: "Czekańska",
      shortDescription: "1902-1991",
      biogram: [
        `Wybitna geograf, dydaktyk geografii, nauczycielka geografii oraz tajnego nauczania, członkini towarzystw naukowych,
         autorka publikacji naukowych i dydaktycznych, w tym podręczników szkolnych. Szeroki zakres zainteresowań naukowych
          obejmował oceanografię, hydrografię, geografię regionalną i metodykę nauczania geografii.`,
        `
        W trakcie swojej powojennej aktywności zawodowej łączyła pracę nauczyciela szkolnego geografii z pracą na 
        Wydziale Matematyczno-Przyrodniczym Uniwersytetu Poznańskiego. Współpracowała z Kuratorium Szkolnego Okręgu Poznańskiego,
        gdzie w latach 1946-1956 kierowała Ośrodkiem Metodycznym Geografii i Geologii. W 1958 r. była przewodniczącą Wydziału Geografii
        Szkolnej Polskiego Towarzystwa Geograficznego, a w 1960 r. na wniosek ministra oświaty powołana na stanowisko przewodniczącej
        Komisji Programowej Geografii oraz Komisji Ocen Podręczników i Szkolnych Pomocy Geograficznych.
        `,
        `Członkini Komitetu Głównego Olimpiady Geograficznej od jej założenia do roku 1989.`
      ],

      photo: "./photos/important_people/czekanska.png",
    },
    // {
    //   id: 5,
    //   title: "",
    //   name: "Florian",
    //   surname: "Plit",
    //   shortDescription: "",
    //   biogram: [
    //     `Urodzony w 1947 w Augustowie. Geograf regionalny, podróżnik, nauczyciel akademicki związany od
    //     początku swojej kariery z Uniwersytetem Warszawskim. Autor wielu publikacji z zakresu dydaktyki
    //     geografii oraz geografii regionalnej świata, a także podręczników szkolnych. Wieloletni redaktor
    //     czasopisma „Geografia w Szkole”. Pełnił funkcję przewodniczącego i wiceprzewodniczącego PTG.`,
    //     `Wieloletni członek Komitetu Głównego Olimpiady Geograficznej w tym przewodniczący Komisji
    //     Zadań, wiceprzewodniczący KGOGiN w latach 1995-1995, członek Komisji Weryfikacyjnej, autor wielu
    //     zadań olimpijskich, przez wiele lat pełnił funkcję przewodniczącego Jury zawodów finałowych.
    //     Wyróżniony Odznaką im. Pani Prof. Dylikowej (Legitymacja nr 80).`,
    //   ],

    //   photo: null,
    // },
    // {
    //   id: 6,
    //   title: "",
    //   name: "Teresa",
    //   surname: "Krynicka—Tarnacka",
    //   shortDescription: "1934-2015",
    //   biogram: [
    //     `Dydaktyk i geograf społeczno—gospodarczy, autorka podręczników szkolnych i publikacji
    //     dydaktycznych. Zaangażowana w szkolenie nauczycieli w WOM w Warszawie oraz w Centralnym
    //     Ośrodku Doskonalenia Nauczycieli. Uczestniczka prac ministerialnych w opracowywaniu programów
    //     szkolnych. Organizatorka konkursów geograficznych w województwie warszawskim.
    //     Wieloletni członek Komitetu Głównego Olimpiady Geograficznej.`,
    //   ],

    //   photo: null,
    // },
    {
      id: 9,
      title: "",
      name: "Jadwiga",
      surname: "Jaroszowa",
      shortDescription: "1900-1984",
      biogram: [
        `Wybitna nauczycielka geografii szkół średnich. Studia ukończyła na Uniwersytecie im. Jana Kazimierza we Lwowie pod kierunkiem sławy nauk geograficznych,
         autora licznych podręczników z tej dziedziny prof. Eugeniusza Romera. Po studiach rozpoczęła pracę jako nauczycielka w gimnazjum Zofii Strzałkowskiej we Lwowie,
          którego sama była absolwentką. W 1938 roku przeniosła się do Warszawy i do wybuchu wojny pracowała w VII Państwowym Gimnazjum i Liceum im. Staszica.
           Pod koniec wojny, otrzymała zlecenie władz oświatowych organizowania szkolnictwa średniego na Pradze Południe, gdzie 15 listopada 1944 roku została 
           mianowana dyrektorką nowo utworzonego na Grochowie VI Liceum Ogólnokształcącego Żeńskiego. Funkcję dyrektora pani Jaroszowa pełniła do 1950 roku.
            Potem była nauczycielką geografii w LO im. Hoffmanowej.`,
        `
        Położyła duże zasługi w pracy nad doskonaleniem kadry nauczycielskiej z zakresu geografii, była kierownikiem i wykładowcą wielu kursów dla nauczycieli przedmiotu,
         ma ogromne zasługi dla organizowania szkolnego ruchu krajoznawczego.
        `,
        `Członkini Komitetu Głównego Olimpiady Geograficznej od jego założenia do roku 1983.`,
      ],

      photo: "./photos/important_people/jaroszowa.png",
    },
    {
      id: 10,
      title: "",
      name: "Halina",
      surname: "Radlicz-Rühlowa",
      shortDescription: "1905-1986",
      biogram: [
        `Absolwentka studiów geograficznych na Uniwersytecie Warszawskim o specjalności w zakresie geomorfologii,
         nauczycielka warszawskich szkół, autorka 20 podręczników szkolnych i książek dla nauczycieli, z których większość
         była wielokrotnie wznawiana. Po wojnie włączyła się w akcję doszkalania nauczycieli w zakresie geologii i geografii, 
         pełniąc w latach 1948-1950 funkcję kierownika Warszawskiego Ośrodka Dydaktyczno-Naukowego.
         Członek Komisji Programowej Ministerstwa Oświaty w zakresie geografii i geologii. Odznaczona m.in. Krzyżem Kawalerskim Orderu Odrodzenia Polski oraz Medalem KEN.
        `,
        `W czasie okupacji uczestniczyła w akcji tajnego nauczania, włączyła się w działania Armii Krajowej, w VII Oddziale Komendy Głównej ZWZ-AK w jednostce 
        zajmującej się pomocą więźniom. Uczestniczka Powstania Warszawskiego.
        `,
        `
        Brała bardzo żywy udział w pracach Komitetu Głównego Olimpiady Geograficznej od jej powstania aż do śmierci,
         służąc swoją wiedzą i doświadczeniem przy układaniu zadań olimpijskich.
        `
      ],

      photo: "./photos/important_people/radlicz_ruhlowa.png",
    },
    {
      id: 11,
      title: "",
      name: "Andrzej",
      surname: "Werwicki",
      shortDescription: "1930-2005",
      biogram: [
        `Geograf, wykładowca, dydaktyk, naukowiec, autor ponad 100 publikacji, głównie z zakresu geografii miast oraz geografii usług.
         W trakcie swojej kariery naukowej związany z Polską Akademią Nauk, Instytutem Kształcenia Nauczycieli oraz Uniwersytetem Łódzkim.
          Odbył wielokrotne staże na uczelniach zagranicznych, gdzie prowadził także wykłady. Współpracownik redakcji wielu czasopism krajowych
           i zagranicznych, w tym m.in. Geographia Polonica oraz Geografii w Szkole..`,
        `Otrzymał m.in.  Złoty Krzyż Zasługi (1976), Medal KEN (1978) a także nagrody Ministra Oświaty i Wychowania (1979). `,
        `Związany z Olimpiadą Geograficzną od jej II edycji. W latach 1980-2001 pełnił funkcję wiceprzewodniczącego Komitetu Głównego Olimpiady Geograficznej.`
      ],

      photo: "./photos/important_people/werwicki.png",
    },
    {
      id: 12,
      title: "",
      name: "Maria",
      surname: "Wilczyńska-Wołoszyn",
      shortDescription: "",
      biogram: [
        `Urodzona w 1943 w Warszawie. Nauczycielka geografii, hydrolog, dydaktyk związana z
        Uniwersytetem Warszawskim. Autorka wielu publikacji dydaktycznych, współautorka podręczników
        szkolnych. Pełniła wiele funkcji związanych z ustalaniem programów szkolnych i zatwierdzaniem
        środków dydaktycznych. Brała udział w pracach Polsko—Niemieckiej Komisji UNESCO ds.
        Podręczników Szkolnych w latach 70—tych.`,
        ` Zaangażowany członek PTG, pełniła między innymi 
        funkcję Sekretarza Zarządu Głównego. Kierownik Organizacyjna w pierwszych 10 latach istnienia Olimpiady Geograficznej,
        wieloletni członek Komitetu Głównego, jedna z inicjatorek powstania Międzynarodowej Olimpiady Geograficznej.`,
      ],

      photo: "./photos/important_people/wilczynska.jpg",
    },
  ];

  const [importantPeople] = useState(importantPeopleData);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("bmd"));

  return (
    <div>
      {
        <div>
          {" "}
          <Box sx={{ position: "sticky", top: 0, zIndex: 99999 }}>
            <ToolBar></ToolBar>
          </Box>
          <PageHeader />
          <Typography
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            variant={isMobile ? "h4" : "h2"}
            sx={{ mt: {bmd: 10, xs: 7} }}
          >
            Oni tworzyli Olimpiadę
          </Typography>
          <Box sx={{display: 'flex', justifyContent: 'center', mt: 5, px: 5}}>
            <Button variant='outlined' onClick={() => window.open(`${window.location.origin}/50_lat.pdf`) }>Zobacz zestawienie osób działających przy organizacji Olimpiady</Button>
          </Box>
          <Grid container spacing={1}>
            {importantPeople.map((person, index) => (
              <Grid item xs={12}>
                <Animation
                  key={person.id}
                  component={
                    <PersonDescription
                      isSmallScreen={isMobile}
                      photo={person.photo}
                      shortDescription={person.shortDescription}
                      biogram={person.biogram}
                      person={
                        person.title + " " + person.name + " " + person.surname
                      }
                      opposite={index % 2}
                    />
                  }
                />
              </Grid>
            ))}
          </Grid>
        </div>
      }
      <Footer/>
    </div>
  );
}
